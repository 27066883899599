import { create } from 'zustand';

export const useAuthStore = create((set) => ({
    user: undefined,
    setUser: (data) => set(() => ({ user: data })),
    setUserFromLocalStorage: () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            set(() => ({ user: JSON.parse(storedUser) }));
        }
    },
    checkTokenExpiration: () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            const token = user.token;

            if (token) {
                const decoded = JSON.parse(atob(token.split('.')[1]));
                const expirationTime = decoded.exp * 1000; // Convert to milliseconds
                const currentTime = Date.now();

                if (currentTime >= expirationTime) {
                    // Clear localStorage and Zustand state
                    localStorage.removeItem('user');
                    set(() => ({ user: undefined }));
                    alert('Session expired. Please log in again.');
                    window.location.href = '/auth'; // Redirect to login page
                }
            }
        }
    },
}));
