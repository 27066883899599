import { useEffect, useState } from "react"
import { applyTeam, getPublicProject } from "../../actions/projectActions"
import { Collapse } from "@mui/material"
import { useAuthStore } from "../../store/store"

const DisplayPublicProjects = () => {

    const [displayProjects, setDisplayProjects] = useState([])

    const data = useAuthStore(state => state.user)

    const [error, setError] = useState({
        errorType: '',
        errorMessage: ''
    })

    const [isExpanded, setIsExpanded] = useState(-1);

    const [showReqs, setShowReqs] = useState(-1)

    useEffect(() => {
        getPublicProject().then(res => {
            if (res.status) {
                setDisplayProjects(res?.projects?.reverse())
                setError({ ...error, errorType: "", errorMessage: '' })
            } else {
                setError({ ...error, errorType: "EMPTY_LIST", errorMessage: 'No Projects Found' })
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleApplyTeam = (data) => {
        applyTeam(data).then(response => {
            console.log(response)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="col-md-10 col-12">
            <div className="d-flex flex-wrap justify-content-center">
                {displayProjects?.map((a, i) => (
                    <div key={i} className="py-2 col-12">
                        <div className="shadow bg-white my-3 p-2 rounded-3">
                            <div className="d-flex col-12 justify-content-between align-items-center">
                                <div className="col-7">
                                    <h3 className="fw-bold text-dark">
                                        {a?.projectTitle}
                                    </h3>
                                </div>
                                <div className="col-5 d-flex flex-wrap justify-content-end align-items-center">
                                    <button className="btn btn-success rounded-pill fw-bold"
                                    onClick={() => {
                                        handleApplyTeam({projectId: a._id, profileId: data.profile._id})
                                    }}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 d-flex pb-4 pt-2 flex-wrap">
                                {a?.projectTech.map((p, index) => (
                                    <div key={index} className="bg-primary rounded-pill px-2 me-2">
                                        <span className="fs-6 fw-bold m-0 text-white">{p}</span>
                                    </div>
                                ))}
                            </div>
                            <p className="fs-5">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: isExpanded === i
                                            ? a?.projectDescription
                                            : `${a?.projectDescription?.substring(0, 100)}...`,
                                    }}
                                />
                                {a?.projectDescription?.length > 100 && (
                                    <small className="text-primary fw-bold"
                                        onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {isExpanded === i ? 'Show Less' : 'Show More'}
                                    </small>
                                )}
                            </p>
                            <hr />
                            <div className="py-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h4>Project Current Requirements</h4>
                                    </div>
                                    <div>
                                        {showReqs === i ? (
                                            <button className="btn btn-danger rounded-pill btn-sm" onClick={() => setShowReqs(-1)}>
                                                Close
                                            </button>
                                        ) : (
                                            <>
                                                {a?.projectCurrentReq?.length === 0 ? (
                                                    <div className="text-center">
                                                        <span className="fs-5 text-danger">No requirements Added</span>
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="btn btn-info rounded-pill btn-sm"
                                                        onClick={() => setShowReqs(i)}
                                                    >
                                                        Show
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <Collapse in={showReqs === i}>
                                    {a?.projectCurrentReq?.length !== 0 ? (
                                        <div>
                                            {a?.projectCurrentReq?.map((p, index) => (
                                                <div key={index}>
                                                    <div className="d-flex align-items-start justify-content-start">
                                                        <span className="fs-5 col-1 text-center">{index + 1}.</span>
                                                        <div className="col-9">
                                                            <p className="m-0 fs-5">{p?.title}</p>
                                                            <p className="m-0 fs-5" dangerouslySetInnerHTML={{ __html: p.desc }}></p>
                                                        </div>
                                                    </div>
                                                    {index !== a?.projectCurrentReq.length - 1 && <hr className="mx-2 mx-md-5" />}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                        </div>
                                    )}
                                </Collapse>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default DisplayPublicProjects