import { useNavigate, Link, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"
import { getProfiles, submitProfile } from "../../actions/userProfileActions"
import { Box, Chip, Collapse, FormControl, IconButton, InputLabel, OutlinedInput, useMediaQuery } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { deSelectRequestedMember, fetchUsers, joinTeamRequest, getSingleProjectForTeam, submitCoHost, getUsersByTechnologies, removeTeamMember, submitProposedAmountHost, submitNegotiationSettle } from "../../actions/projectActions"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"
import MenuIcon from '@mui/icons-material/Menu';

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'black'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'black',
    label: 'black',
    icon: 'black',
    '& label': {
        color: '#0275d8',
    },
    '& .MuiSvgIcon-root': {
        color: 'black',
    },
}));


const ManageTeam = () => {
    const navigate = useNavigate()

    const project_id = useParams().pId

    const [refresh, setRefresh] = useState(false)

    const [projectData, setProjectData] = useState({})
    const [showTeam, setShowTeam] = useState(true)
    const [searchUsers, setSearchUsers] = useState(false)
    const [showSelectedUsers, setShowSelectedUsers] = useState(false)
    const [showUpdateCoHost, setShowUpdateCoHost] = useState(false)
    const [displayOutlet, setDisplayOutlet] = useState(false)
    const [fetchedUser, setFetchedUser] = useState([])
    const [teamCollapsible, setTeamCollapsible] = useState(-1)
    const [showCollapsible, setShowCollapsible] = useState(-1);
    const [showCollapsibleRemoved, setShowCollapsibleRemoved] = useState(-1);
    const [showCollapsibleReason, setShowCollapsibleReason] = useState(-1);
    const [showSelectedCollapsible, setShowSelectedCollapsible] = useState(-1);
    const [selectProjectCoHost, setSelectProjectCoHost] = useState('')
    const [recomProfile, setRecomProfile] = useState([])

    const [showSelectForm, setShowSelectForm] = useState(-1)
    const [activeCollapseNumber, setActiveCollapseNumber] = useState(null);

    const [values, setValues] = useState({
        text: '',
        error: false,
        errorMessage: ''
    })

    const isMdScreen = useMediaQuery('(min-width: 786px)');
    const [showMenu, setShowMenu] = useState(false)

    const { text, error, errorMessage } = values

    const [requestedTotalAmount, setRequestedTotalAmount] = useState(0)
    const [joinedTotalAmount, setJoinedTotalAmount] = useState(0)

    const [negotiationForm, setNegotiationForm] = useState(-1)

    const [proposedAmountHost, setProposedAmountHost] = useState(null)

    useEffect(() => {
        getSingleProjectForTeam(project_id).then(res => {
            console.log(res.usersApplied)
            setProjectData(res)
            let totalAmount = 0;
            let jTotalAmount = 0

            for (let i = 0; i < res.projectTeamRequested.length; i++) {
                const element = res.projectTeamRequested[i];
                const pendingRequests = element.devSembleProfile?.pendingRequests || [];

                for (let j = 0; j < pendingRequests.length; j++) {
                    let requestAmount = parseFloat(pendingRequests[j].amount) || 0;

                    const negotiation = res.negotiations.find(neg =>
                        neg.userId.toString() === element._id.toString() &&
                        neg.isSettled === false
                    );

                    if (negotiation && negotiation.proposedAmountHost) {
                        requestAmount = negotiation.proposedAmountHost;
                    }

                    totalAmount += requestAmount;
                }
            }

            setRequestedTotalAmount(totalAmount);

            for (let i = 0; i < res.projectTeam.length; i++) {
                const element = res.projectTeam[i];
                const teamJoined = element.devSembleProfile?.teamJoined || [];

                for (let j = 0; j < teamJoined.length; j++) {
                    jTotalAmount += parseFloat(teamJoined[j].amount) || 0;
                }
            }

            setJoinedTotalAmount(jTotalAmount)

            getUsersByTechnologies(res.projectTech).then(res => {
                setRecomProfile(res)
            }).catch(err => {
                console.log("tech fetch user error", err)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [refresh])

    const handleChange = name => event => {
        setValues({ ...values, error: false, errorMessage: '', [name]: event.target.value })
        if (event.target.value.length <= 2) {
            setFetchedUser([])
        } else {
            fetchUsers(event.target.value).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }
    useEffect(() => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [text.length <= 2])

    const handleDeSelectRequestedMember = (projectId, selectedId) => {
        deSelectRequestedMember({ projectId, selectedId }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleRemoveTeamMember = (projectId, selectedId) => {
        removeTeamMember({ projectId, selectedId, removalReason }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handlePostSearch = (text) => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const [sudoRequest, setSudoRequest] = useState([])
    const [sudoUserRequestIds, setSudoUserRequestIds] = useState([])
    const [collapseNumber, setCollapseNumber] = useState(0)

    const handleAddTeamRequest = (user) => {
        setSudoUserRequestIds((sudoUserRequestIds) => {
            const existingIndex = sudoUserRequestIds.findIndex(req => req.userId === user.userId._id);

            if (existingIndex !== -1) {
                const updatedRequests = [...sudoUserRequestIds];
                updatedRequests[existingIndex] = {
                    ...updatedRequests[existingIndex],
                    amount,
                    requirements: selectedReqs,
                };
                return updatedRequests;
            } else {
                return [
                    ...sudoUserRequestIds,
                    {
                        userId: user.userId._id,
                        name: `${user.userId.firstName} ${user.userId.lastName}`,
                        amount,
                        requirements: selectedReqs,
                    },
                ];
            }
        });

        setSudoRequest((sudoRequest) => {
            if (!sudoRequest.find(req => req.userId === user.userId._id)) {
                return [...sudoRequest, user];
            }
            return sudoRequest;
        });

        setAmount('');
        setSelectedReqs([]);
    };

    const onSubmitRequests = (projectId, selectedRequests) => {
        const payload = selectedRequests.map(req => ({
            userId: req.userId,
            amount: req.amount,
            requirements: req.requirements.map(reqItem => ({
                title: reqItem.title,
                desc: reqItem.desc,
                _id: reqItem._id
            }))
        }));

        joinTeamRequest({ projectId, selectedIds: payload })
            .then(res => {
                if (res) {
                    setRefresh(true);
                    setShowSelectedUsers(false);
                    setSudoRequest([]);
                    setSudoUserRequestIds([]);
                }
            })
            .catch(err => {
                console.log(err);
            });

        setRefresh(false);
    };


    const [removalReason, setRemovalReason] = useState(''); // Store the reason for removal
    const [showModal, setShowModal] = useState(-1); // Control modal visibility

    const handleConfirmRemove = (projectId, memberId, removalReason) => {
        handleRemoveTeamMember(projectId, memberId, removalReason);
    };
    const handleRemoveTeamRequest = (userId) => {
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e.userId._id) !== JSON.stringify(userId.userId)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e.userId) !== JSON.stringify(userId.userId)))
    }

    const handleChangeCoHost = (event) => {
        setSelectProjectCoHost(event.target.value)
    }

    const showRequestButton = ({ projectData, profile }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeam[i]?._id) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id && profile.userId._id === sudoRequest[i]?.userId._id) {
                return (<button onClick={() => handleRemoveTeamRequest(profile)} className="btn btn-sm   btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id !== sudoRequest[i]?.userId._id) {
                return (
                    <button
                        onClick={() => {
                            handlePostSearch(text)
                            setSearchUsers(true)
                            setShowSelectedUsers(true)
                            handleAddTeamRequest(profile)
                            setShowSelectForm(-1)
                        }} className="btn btn-sm btn-success   rounded-pill">
                        Select
                    </button>
                )
            }
        }
    }

    const showRequestFormButton = ({ projectData, profile, index }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeam[i]?._id) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id && profile.userId._id === sudoRequest[i]?.userId._id) {
                return (<button onClick={() => handleRemoveTeamRequest(profile)} className="btn btn-sm   btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id !== sudoRequest[i]?.userId._id) {
                return (

                    showSelectForm === index ? (
                        <button onClick={() => {
                            setShowSelectForm(-1)
                        }} className="btn btn-sm btn-danger rounded-pill  ">
                            Close
                        </button>
                    ) : (
                        <button onClick={() => {
                            setShowSelectForm(index)
                        }} className="btn btn-sm btn-success rounded-pill  ">
                            Select
                        </button>
                    )

                )
            }
        }
    }

    const handleSubmitCoHost = ({ projectId, userId }) => {
        submitCoHost({ projectId, userId }).then(res => {
            if (res) {
                setRefresh(true)
                setShowUpdateCoHost(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const [amount, setAmount] = useState('');
    const [selectedReqs, setSelectedReqs] = useState([]);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleReqChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedReqs(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleRemoveReq = (reqId, event) => {
        event.stopPropagation();
        setSelectedReqs(selectedReqs.filter((req) => req._id !== reqId));
    };

    const displayBudget = () => {

        let totalAssigned = 0

        sudoUserRequestIds.map((s, i) => {
            totalAssigned = totalAssigned + parseFloat(s?.amount)
        })

        return (
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-3">
                <div className="col-12 text-center">
                    Your Budget:
                    {projectData?.budget?.budgetAmount}
                </div>
                <div className="col-12 d-flex flex-wrap justify-content-center align-items-center gap-5">
                    <div>
                        Assigning right now:
                        {totalAssigned}
                    </div>
                    <div>
                        Balance:
                        {projectData?.budget?.budgetAmount - totalAssigned - requestedTotalAmount - joinedTotalAmount}
                    </div>
                </div>
            </div>
        )
    }

    const handleProposedAmountChange = (e) => {
        setProposedAmountHost(e.target.value);
    };

    const handleSubmitProposedAmountHost = ({ projectId, userId, proposedAmountHost }) => {
        submitProposedAmountHost({ projectId, userId, proposedAmountHost }).then(res => {
            console.log(res)
            if (res) {
                setRefresh(true)
                setNegotiationForm(-1)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleSettledAmount = ({ projectId, userId, settledAmount }) => {
        submitNegotiationSettle({ projectId, userId, settledAmount }).then(res => {
            console.log(res)
            if (res) {
                setRefresh(true)
                setNegotiationForm(-1)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className={`d-lg-none d-flex flex-wrap justify-content-start align-items-start shadow-sm rounded-3 px-2 ${showMenu ? 'bg-white shadow py-1' : ''}`} style={{
                    position: "sticky", zIndex: 10, top: 10
                }}>
                    <div className="col-12 d-flex flex-wrap justify-content-between align-items-center bg-white">
                        <div>
                            <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData._id}`}>
                                <button className="rounded-pill btn btn-sm btn-warning">
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                                </button>
                            </Link>
                        </div>
                        <div className="fs-5 text-center col-9 px-3 py-2">
                            {projectData.projectTitle}
                        </div>
                        <div className={`${showMenu ? "" : 'bg-white shadow'}`} style={{
                            borderRadius: '50vh'
                        }}>
                            <IconButton onClick={() => setShowMenu(!showMenu)} >
                                <MenuIcon sx={{ strokeWidth: 3 }} />
                            </IconButton>
                        </div>
                    </div>
                    <Collapse in={showMenu} className="bg-light col-12">
                        <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                            <div className="py-md-4">
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(0)
                                                setActiveCollapseNumber(0)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Search developers
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 0 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(1)
                                                setActiveCollapseNumber(1)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Users Selected<sup className="px-1 rounded-pill m-1">{sudoUserRequestIds.length}</sup>
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 1 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link" >
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(2)
                                                setActiveCollapseNumber(2)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Team Members
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 2 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(3)
                                                setActiveCollapseNumber(3)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Users Requested
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 3 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(4)
                                                setActiveCollapseNumber(4)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Members Removed
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 4 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(5)
                                                setActiveCollapseNumber(5)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Users Applied
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 5 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className={` ${isMdScreen ? "col-10 gap-4 py-5" : 'col-11'} container-fluid d-flex justify-content-center align-items-start m-auto px-1 pb-5`}>
                    <div className={isMdScreen ? "col-lg-3" : 'd-none'}>
                        <div className="rounded-3 col-10">
                            <div className="rounded-3 p-3 d-lg-block d-none " style={{
                                backgroundColor: '#daebf9'
                            }}>
                                <div className={isMdScreen ? "d-flex flex-wrap justify-content-start align-items-center" : 'd-none'}>
                                    <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData._id}`} className="" style={{ textDecoration: 'none' }}>
                                        <button className="btn btn-warning btn-sm rounded-pill">
                                            <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-2 fw-bold">back</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="d-lg-block d-none card bg-transparent py-2 rounded-lg border-0">
                                    <div className="d-flex flex-column rounded-3 align-items-start flex-wrap justify-content-end">
                                        <div className="fs-2 fw-bold text-left">
                                            {projectData.projectTitle}
                                        </div>
                                        <div className="py-md-4">
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(0)
                                                            setActiveCollapseNumber(0)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Search developers
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 0 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(1)
                                                            setActiveCollapseNumber(1)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Users Selected<sup className="px-1 m-1">{sudoUserRequestIds.length}</sup>
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 1 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" >
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(2)
                                                            setActiveCollapseNumber(2)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Team Members
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 2 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(3)
                                                            setActiveCollapseNumber(3)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Users Requested
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 3 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(4)
                                                            setActiveCollapseNumber(4)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Members Removed
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 4 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(5)
                                                            setActiveCollapseNumber(5)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Users Applied
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 5 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="p-md-3 col-lg-8 col-12 rounded-3 p-2" style={{
                        backgroundColor: '#daebf9'
                    }}>
                        <div className="py-3 d-flex flex-wrap justify-content-center">
                            {displayBudget()}
                        </div>
                        <Collapse in={collapseNumber === 0}>
                            <div>
                                <div>
                                    <h3 className=" ">Fetch your perfect team here</h3>
                                </div>
                                <div>
                                    <div className="col-12 my-2">
                                        <CssTextField
                                            className=' col-12 bg-white'
                                            error={errorMessage === "" ? false : true}
                                            id="custom-css-outlined-input"
                                            color={error ? "error" : ""}
                                            value={text}
                                            autoComplete='off'
                                            label={errorMessage === '' ? "Enter single tech to search user" : errorMessage}
                                            onChange={handleChange('text')}
                                            variant="outlined" />
                                        <label className="text-danger fs-6">*Type single tech to fetch users</label>
                                    </div>
                                </div>
                                <div className="">
                                    {fetchedUser.length === 0 ? <div className="container-fluid card border-0 p-md-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                        <div className="pt-2  ">
                                            <h3>Recommended by devSemble for: </h3>
                                            <div className="pb-2 d-flex flex-wrap">{projectData?.projectTech?.map((t, i) => (
                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                    <span className="fw-bold">{t}</span>
                                                </div>
                                            ))}</div>
                                        </div>
                                        <hr className="mt-0" />
                                        {recomProfile.map((f, i) => (
                                            <div key={i} style={{
                                                borderBottom: '0.15rem solid grey',
                                                borderWidth: 'thin',
                                                borderTopLeftRadius: '0.30rem',
                                                borderTopRightRadius: '0.30rem',
                                                backgroundColor: 'white',
                                                // boxShadow: '0 -0.1rem 0.25rem grey'
                                            }} className="p-2 my-1">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                    <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-6 fs-5   ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                        <div className="col-6 text-end">
                                                            {f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                        </div>
                                                    </div>
                                                    <div className={`col-12 col-md-6 d-flex ${isMdScreen ? 'justify-content-end' : 'justify-content-between'} gap-4 align-items-center`}>
                                                        {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                        {showCollapsible === i ?
                                                            <button onClick={() => setShowCollapsible(-1)} className="btn btn-sm btn-danger   rounded-pill">
                                                                Close
                                                            </button> :
                                                            <button onClick={() => setShowCollapsible(i)} className="btn btn-sm btn-primary   rounded-pill">
                                                                View Profile
                                                            </button>}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <Collapse in={showSelectForm === i}>
                                                        <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                            <div className="col-12">
                                                                <CssTextField
                                                                    className="col-12 my-2"
                                                                    variant="outlined"
                                                                    value={amount}
                                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                    type="number"
                                                                    onChange={handleAmountChange}
                                                                    label="Amount"
                                                                    multiline
                                                                    maxRows="4"
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <FormControl variant="outlined" className="col-12">
                                                                    <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                                    <Select
                                                                        labelId="requirement-label"
                                                                        id="requirement-select"
                                                                        multiple
                                                                        value={selectedReqs}
                                                                        onChange={handleReqChange}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((req) => (
                                                                                    <Chip
                                                                                        key={req._id}
                                                                                        label={req.title}
                                                                                        className="bg-primary text-white   fs-6"
                                                                                    />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                    >
                                                                        {projectData.projectCurrentReq.map((req) => (
                                                                            <MenuItem key={req._id} value={req}>
                                                                                {req.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 d-flex flex-wrap justify-content-end">
                                                                <div className="">
                                                                    {showRequestButton({ projectData: projectData, profile: f })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <Collapse className="" in={showCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {f?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <small key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                            <span className="small fw-bold">{t}</span>
                                                                                        </small>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="col-12 m-0" />
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {f?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{e.eduTitle}</span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* <hr /> */}
                                            </div>
                                        ))}
                                    </div> : <div className="card py-2 border-0 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                        {fetchedUser.map((f, i) => (
                                            <div key={i} style={{
                                                borderBottom: '0.15rem solid grey',
                                                borderWidth: 'thin',
                                                borderTopLeftRadius: '0.30rem',
                                                borderTopRightRadius: '0.30rem',
                                                backgroundColor: 'white',
                                                // boxShadow: '0 -0.1rem 0.25rem grey'
                                            }} className="p-2 my-1">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                    <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-6 fs-5   ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                        <div className="col-6  ">
                                                            {!f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                        {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                        {showCollapsible === i ?
                                                            <button onClick={() => setShowCollapsible(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                                Close
                                                            </button> :
                                                            <button onClick={() => setShowCollapsible(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                                View Profile
                                                            </button>}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <Collapse in={showSelectForm === i}>
                                                        <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                            <div className="col-12">
                                                                <CssTextField
                                                                    className="col-12 my-2"
                                                                    variant="outlined"
                                                                    value={amount}
                                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                    type="number"
                                                                    onChange={handleAmountChange}
                                                                    label="Amount"
                                                                    multiline
                                                                    maxRows="4"
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <FormControl variant="outlined" className="col-12">
                                                                    <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                                    <Select
                                                                        labelId="requirement-label"
                                                                        id="requirement-select"
                                                                        multiple
                                                                        value={selectedReqs}
                                                                        onChange={handleReqChange}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((req) => (
                                                                                    <Chip
                                                                                        key={req._id}
                                                                                        label={req.title}
                                                                                        className="bg-primary text-white   fs-6"
                                                                                    />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                    >
                                                                        {projectData.projectCurrentReq.map((req) => (
                                                                            <MenuItem key={req._id} value={req}>
                                                                                {req.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 d-flex flex-wrap justify-content-end">
                                                                <div className="">
                                                                    {showRequestButton({ projectData: projectData, profile: f })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <Collapse className="" in={showCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {f?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <small key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                            <span className="small fw-bold">{t}</span>
                                                                                        </small>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="col-12 m-0" />
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {f?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{e.eduTitle}</span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 2}>
                            <div>
                                {projectData.projectCoHost === undefined ? <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    {projectData?.projectTeam?.length >= 2 ? <>
                                        <span className="fs-4  ">Select Co-Host</span>
                                        <div className="col-6">
                                            <StyledSelect
                                                className="col-12 bg-white"
                                                id="demo-simple-select"
                                                value={selectProjectCoHost}
                                                label="Select Co-Host"
                                                variant="outlined"
                                                onChange={handleChangeCoHost}
                                            >
                                                {projectData.projectTeam?.map((p, i) => (
                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                ))}
                                            </StyledSelect>
                                        </div>
                                        <button
                                            onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                            className="btn btn-primary   rounded-pill">Add as Co-Host</button>
                                    </> : <span className="text-danger  ">*For Co-host Need atleast 2 team members</span>}
                                </div> : <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <span className="fs-3  ">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                    {showUpdateCoHost ?
                                        <button onClick={() => setShowUpdateCoHost(false)} className="btn btn-sm btn-danger rounded-pill">Close</button> :
                                        <button onClick={() => setShowUpdateCoHost(true)} className="btn btn-sm   btn-info rounded-pill">Update Co-host</button>
                                    }
                                </div>}
                                <Collapse in={showUpdateCoHost}>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <span className="fs-4  ">Select Co-Host</span>
                                        <div className="col-6">
                                            <StyledSelect
                                                className="col-12"
                                                id="demo-simple-select"
                                                value={selectProjectCoHost}
                                                label="Select Co-Host"
                                                variant="outlined"
                                                onChange={handleChangeCoHost}
                                            >
                                                {projectData.projectTeam?.map((p, i) => (
                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                ))}
                                            </StyledSelect>
                                        </div>
                                        <button
                                            onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                            className="btn btn-sm   btn-primary rounded-pill">Add as Co-Host</button>
                                    </div>
                                </Collapse>
                                <hr className="m-1" />
                            </div>
                            <div className="p-2 m-2 col-12 border-0 bg-transparent rounded-lg">
                                <span className="fs-3"><b>Team Members</b></span>
                                {projectData.projectTeam?.length === 0 ? <div>
                                    <span>No Team members</span>
                                </div> : <div>
                                    {projectData.projectTeam?.map((p, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                        }} className="p-2 my-1">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-7">
                                                    <span className="col-6 fs-5   ">{p?.firstName} {p?.lastName}</span>
                                                </div>
                                                <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                    {showModal === i ?
                                                        <button
                                                            onClick={() => setShowModal(-1)}
                                                            className="btn btn-sm   btn-danger rounded-pill"
                                                        >
                                                            Close
                                                        </button> :
                                                        <button
                                                            onClick={() => setShowModal(i)}
                                                            disabled={p._id === projectData?.authorizedGitAccess || p._id === projectData?.projectCoHost?._id}
                                                            className="btn btn-sm   btn-danger rounded-pill"
                                                        >
                                                            Remove
                                                        </button>}
                                                    {teamCollapsible === i ?
                                                        <button onClick={() => setTeamCollapsible(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setTeamCollapsible(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                            View Profile
                                                        </button>}
                                                </div>
                                            </div>
                                            <Collapse in={showModal === i}>
                                                <div className="py-2">
                                                    <div className="">
                                                        <h5>Remove Team Member</h5>
                                                        <p>Please provide a reason for removing this team member:</p>
                                                        <CssTextField
                                                            className="col-12"
                                                            variant="outlined"
                                                            value={removalReason}
                                                            onChange={(e) => setRemovalReason(e.target.value)}
                                                            label="Enter reason for removal"
                                                            multiline
                                                            maxRows="4"
                                                        />
                                                        <div className="py-1 text-end">
                                                            <button
                                                                onClick={() => handleRemoveTeamMember(projectData._id, p._id)}
                                                                className="btn btn-danger rounded-pill  "
                                                                disabled={!removalReason.trim()}
                                                            >
                                                                Confirm Removal
                                                            </button>
                                                            <button
                                                                onClick={() => setShowModal(-1)}
                                                                className="btn btn-secondary rounded-pill   ms-md-2"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <Collapse className="" in={teamCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 3}>
                            <div className="card p-2 m-2 col-12 border-0 bg-transparent rounded-lg">
                                <span className="fs-3"><b>Users Requested</b></span>
                                {projectData.projectTeamRequested?.length === 0 ? <div>
                                    <span>No one requested</span>
                                </div> : <div>
                                    {projectData.projectTeamRequested?.map((p, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                        }} className="p-2 my-1">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-7">
                                                    <span className="col-6 fs-5   ">{p?.firstName} {p?.lastName} <span className="fs-6">({p?.devSembleProfile?.pendingRequests[0]?.amount})</span></span>
                                                </div>
                                                <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                    <button onClick={() => handleDeSelectRequestedMember(projectData._id, p._id)} className="btn btn-sm   btn-danger rounded-pill">
                                                        Remove
                                                    </button>
                                                    {showCollapsible === i ?
                                                        <button onClick={() => setShowCollapsible(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowCollapsible(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                            Show Assigned
                                                        </button>}
                                                </div>
                                                <div className="col-12">
                                                    {!p?.negotiation?.proposedAmountUser ? null :
                                                        <> {p?.negotiation?.isSettled ? <>
                                                            <div>
                                                                {p?.negotiation?.settledAmount}
                                                            </div>
                                                        </> : <>
                                                            <hr className="" />
                                                            <div className="d-flex flex-wrap justify-content-between col-12">
                                                                {!p?.negotiation?.proposedAmountUser ? null :
                                                                    <span>
                                                                        User Proposed: {p?.negotiation?.proposedAmountUser}
                                                                    </span>
                                                                }
                                                                {!p?.negotiation?.proposedAmountHost ? null :
                                                                    <span>
                                                                        Host Proposed: {p?.negotiation?.proposedAmountHost}
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-around">
                                                                <a
                                                                    className="bg-transparent"
                                                                    onClick={() => { negotiationForm === i ? setNegotiationForm(-1) : setNegotiationForm(i) }}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        textDecorationLine: 'none',
                                                                        color: negotiationForm === i ? "red" : ""
                                                                    }}
                                                                >
                                                                    {negotiationForm === i ? "Close" : " Want to negotiate?"}
                                                                </a>
                                                                <span>OR</span>
                                                                <a
                                                                    className="bg-transparent text-success"
                                                                    onClick={() => handleSettledAmount({
                                                                        projectId: project_id,
                                                                        userId: p?._id,
                                                                        settledAmount: p?.negotiation?.proposedAmountUser
                                                                    })}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        textDecorationLine: 'none',
                                                                        color: negotiationForm === i ? "red" : ""
                                                                    }}
                                                                >
                                                                    Accept
                                                                </a>
                                                            </div>
                                                            <Collapse in={negotiationForm === i} className="col-12">
                                                                <div className="d-flex flex-wrap justify-content-between gap-1 align-items-center">
                                                                    <div className="col-9">
                                                                        <TextField
                                                                            className="col-12 my-2"
                                                                            variant="outlined"
                                                                            value={proposedAmountHost}
                                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                            type="number"
                                                                            onChange={handleProposedAmountChange}
                                                                            label="Propose Amount"
                                                                        />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <button onClick={() => {
                                                                            handleSubmitProposedAmountHost({
                                                                                projectId: project_id,
                                                                                userId: p?._id,
                                                                                proposedAmountHost: proposedAmountHost
                                                                            })
                                                                        }} className="col-12 btn btn-success rounded-pill fw-bold">
                                                                            Send
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <Collapse className="" in={showCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className=" col-md-12 col-12 m-1 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Requirements Assigned</b></span>
                                                            <div>
                                                                {p?.devSembleProfile?.pendingRequests[0]?.requirements.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{i + 1}. {w.title}</span>
                                                                        <div className="d-flex flex-wrap">
                                                                            {w.description}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 4}>
                            <div className="card p-2 m-2 col-12 border-0 bg-transparent rounded-lg">
                                <span className="fs-3"><b>Members Removed</b></span>
                                {projectData.membersRemoved?.length === 0 ? <div>
                                    <span>Well you have a strong team then</span>
                                </div> : <div>
                                    {projectData.membersRemoved?.map((p, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                        }} className="p-2 my-1">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-7">
                                                    <span className="col-6 fs-5   ">{p?.member?.firstName} {p?.member?.lastName}</span>
                                                </div>
                                                <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                    {/* {showRequestButton({ projectData: projectData, profile: p?.member?.devSembleProfile })} */}
                                                    {showCollapsibleReason === i ?
                                                        <button onClick={() => setShowCollapsibleReason(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowCollapsibleReason(i)} className="btn btn-sm   btn-warning rounded-pill">
                                                            View Reason
                                                        </button>
                                                    }
                                                    {showCollapsibleRemoved === i ?
                                                        <button onClick={() => setShowCollapsibleRemoved(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowCollapsibleRemoved(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                            View Profile
                                                        </button>}
                                                </div>
                                            </div>
                                            <Collapse in={showCollapsibleReason === i} >
                                                <div className="py-2">
                                                    {p.removalReason}
                                                </div>
                                            </Collapse>
                                            <Collapse className="" in={showCollapsibleRemoved === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {p?.member?.devSembleProfile?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {p?.member?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 5}>
                            <div>
                                <div className="container-fluid card border-0 p-md-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                    {projectData.usersApplied?.length === 0 ? <div>
                                        <span>No Applied Users</span>
                                    </div> : <div>
                                        {projectData?.usersApplied?.map((f, i) => (
                                            <div key={i} style={{
                                                borderBottom: '0.15rem solid grey',
                                                borderWidth: 'thin',
                                                borderTopLeftRadius: '0.30rem',
                                                borderTopRightRadius: '0.30rem',
                                                backgroundColor: 'white',
                                                // boxShadow: '0 -0.1rem 0.25rem grey'
                                            }} className="p-2 my-1">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                    <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-6 fs-5   ">{f?.userId?.firstName} {f?.userId?.lastName}</div>
                                                        <div className="col-6 text-end">
                                                            {f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                        </div>
                                                    </div>
                                                    <div className={`col-12 col-md-6 d-flex ${isMdScreen ? 'justify-content-end' : 'justify-content-between'} gap-4 align-items-center`}>
                                                        {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                        {showCollapsible === i ?
                                                            <button onClick={() => setShowCollapsible(-1)} className="btn btn-sm btn-danger   rounded-pill">
                                                                Close
                                                            </button> :
                                                            <button onClick={() => setShowCollapsible(i)} className="btn btn-sm btn-primary   rounded-pill">
                                                                View Profile
                                                            </button>}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <Collapse in={showSelectForm === i}>
                                                        <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                            <div className="col-12">
                                                                <CssTextField
                                                                    className="col-12 my-2"
                                                                    variant="outlined"
                                                                    value={amount}
                                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                    type="number"
                                                                    onChange={handleAmountChange}
                                                                    label="Amount"
                                                                    multiline
                                                                    maxRows="4"
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <FormControl variant="outlined" className="col-12">
                                                                    <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                                    <Select
                                                                        labelId="requirement-label"
                                                                        id="requirement-select"
                                                                        multiple
                                                                        value={selectedReqs}
                                                                        onChange={handleReqChange}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((req) => (
                                                                                    <Chip
                                                                                        key={req._id}
                                                                                        label={req.title}
                                                                                        className="bg-primary text-white   fs-6"
                                                                                    />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                    >
                                                                        {projectData.projectCurrentReq.map((req) => (
                                                                            <MenuItem key={req._id} value={req}>
                                                                                {req.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 d-flex flex-wrap justify-content-end">
                                                                <div className="">
                                                                    {showRequestButton({ projectData: projectData, profile: f })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <Collapse className="" in={showCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {f?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <small key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                            <span className="small fw-bold">{t}</span>
                                                                                        </small>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="col-12 m-0" />
                                                            <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-5"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {f?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-6">{e.eduTitle}</span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* <hr /> */}
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 1}>
                            <div className="card border-0 my-3 p-2">
                                <div className="d-flex flex-wrap justify-content-between bg-light align-items-start">
                                    <div className="">
                                        <span className="fs-3  ">Team Members you selected</span>
                                    </div>
                                    {sudoUserRequestIds.length === 0 ? null :
                                        <button onClick={() => onSubmitRequests(projectData._id, sudoUserRequestIds)} className="btn my-2 btn-success col-md-4 rounded-pill">
                                            <span className="ps-lg-3  ">Send Requests</span>
                                        </button>}
                                </div>
                                <div className="conatiner-fluid card p-2 bg-light border-0 " style={{ height: "50vh", overflowY: "scroll" }}>
                                    {sudoUserRequestIds.map((s, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                        }} className="p-2 my-1 rounded">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-6">
                                                    <span className="fs-4  ">{s.name} <span className="fs-6">({s.amount})</span></span>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                                                    <button onClick={() => handleRemoveTeamRequest(s)} className="btn btn-sm   btn-danger rounded-pill">
                                                        Remove
                                                    </button>
                                                    {showSelectedCollapsible === i ?
                                                        <button onClick={() => setShowSelectedCollapsible(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowSelectedCollapsible(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                            Show Assigned
                                                        </button>}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div>
                                                </div>
                                                <Collapse className="" in={showSelectedCollapsible === i}>
                                                    <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                        <hr className="col-12" />
                                                        <div className=" col-md-12 col-12 m-1 bg-transparent border-secondary rounded-lg">
                                                            <div>
                                                                <span className="fs-4"><b>Requirements Assigned</b></span>
                                                                <div>
                                                                    {s?.requirements.map((w, i) => (
                                                                        <div key={i} className="">
                                                                            <span className="fs-4">{i + 1}. {w.title}</span>
                                                                            <div className="d-flex flex-wrap">
                                                                                {w.description}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageTeam