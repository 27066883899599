import { Collapse, IconButton } from "@mui/material"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../authFlow/assets/Frame_6devSemble_logo.png"

const Navbar = () => {

    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false)
    const [displayCommunity, setDisplayCommuntiy] = useState(false)

    return (
        <div>
            <div className="py-2" style={{ backgroundColor: 'transparent' }}>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="p-2 px-3 col-11 col-lg-10 border-0 rounded-pill d-flex justify-content-between align-items-center">
                        <div className="mx-lg-auto ml-auto d-flex align-items-center">
                            {/* Logo and Name */}
                            <Link to="/" className="text-decoration-none d-flex align-items-center">
                                {/* Logo */}
                                <img
                                    src={logo}
                                    alt="devSemble Logo"
                                    className="d-inline-block align-top"
                                    style={{ height: '40px', marginRight: '10px' }}
                                />
                                {/* Name */}
                                <h2
                                    className="m-0 mx-auto d-none d-lg-block"
                                    style={{ fontWeight: 'bolder' }}
                                >
                                    devSemble
                                </h2>
                            </Link>
                        </div>

                        <div className=" rounded-pill"
                            style={{
                                boxShadow: "0rem 0rem 0.5rem #0275d8",
                                wordWrap: "break-word",

                            }}
                        >
                            <div className="d-lg-none d-block">
                                <IconButton onClick={() => setIsOpen(!isOpen)} >
                                    <MenuIcon />
                                </IconButton>
                                <div className="col-12 d-flex flex-wrap justify-content-center" style={{ position: 'absolute', right: '0px', zIndex: 2 }}>
                                    <Collapse in={isOpen} className=" col-10 d-flex flex-wrap justify-content-center">
                                        <div className="p-2 m-2 rounded bg-white"
                                            style={{ boxShadow: "0rem 0.5rem 1rem #0275d8", wordWrap: 'break-word' }}>
                                            <div className="" style={{ textAlign: 'right' }}>
                                                <div className="py-2">
                                                    <Link to="/about-us" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">About Us</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <Link to="/blogs" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">Explore Blogs</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <Link onClick={() => setDisplayCommuntiy(!displayCommunity)} className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">Community</h5>
                                                    </Link>
                                                    <Collapse in={displayCommunity}>
                                                        <div className="">
                                                            <ul className="list-unstyled text-muted">
                                                                <li>
                                                                    <Link to="/students" className="">
                                                                        <h5 className="m-0 mx-auto">Students</h5>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/freelancers" className="">
                                                                        <h5 className="m-0 mx-auto">Freelancers</h5>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/project-listers" className="">
                                                                        <h5 className="m-0 mx-auto">Project Listers</h5>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                <div className="py-2">
                                                    <Link to="/devs-cli" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">devS-CLI</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <Link to="/public-display-rank-list" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">Ranked Users</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <a href="/auth" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto">Get Started</h5>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div className="mx-5 my-2 d-lg-flex d-none flex-wrap justify-content-between align-items-center">
                                <div className="px-2">
                                    <Link to="/about-us" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/about-us' ? 'active' : ''}`}>
                                        <h5 className="m-0 mx-auto">About Us</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <Link to="/blogs" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/blogs' ? 'active' : ''}`}>
                                        <h5 className="m-0 mx-auto">Explore Blogs</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <button onClick={() => setDisplayCommuntiy(!displayCommunity)} className={`custom-link btn btn-sm rounded-pill`}>
                                        <h5 className="m-0 mx-auto">Community</h5>
                                    </button>
                                    <Collapse in={displayCommunity} className="position-absolute">
                                        <div className="bg-white shadow">
                                            <ul className="list-unstyled text-muted">
                                                <li className="py-2 p-2">
                                                    <Link to="/students" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/students' ? 'active' : ''}`}>
                                                        <h5 className="m-0 mx-auto">Students</h5>
                                                    </Link>
                                                </li>
                                                <li className="py-2 p-2">
                                                    <Link to="/freelancers" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/freelancers' ? 'active' : ''}`}>
                                                        <h5 className="m-0 mx-auto">Freelancers</h5>
                                                    </Link>
                                                </li>
                                                <li className="py-2 p-2">
                                                    <Link to="/project-listers" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/project-listers' ? 'active' : ''}`}>
                                                        <h5 className="m-0 mx-auto">Project Listers</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="px-2">
                                    <Link to="/devs-cli" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/devs-cli' ? 'active' : ''}`}>
                                        <h5 className="m-0 mx-auto">devS-CLI</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <Link to="/public-display-rank-list" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/public-display-rank-list' ? 'active' : ''}`}>
                                        <h5 className="m-0 mx-auto">Ranked Users</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <a href="/auth" className={`custom-link btn btn-sm rounded-pill ${location.pathname === '/auth' ? 'active' : ''}`}>
                                        <h5 className="m-0 mx-auto">Get Started</h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar