import AuthNavbar from "../AuthNavbar"
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import Autocomplete from '@mui/material/Autocomplete';
import { Collapse, IconButton, setRef, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { createProject, getUserProject, deleteProject, updateProject } from "../../actions/projectActions";
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill theme CSS

const CssTextField = styled(TextField, Autocomplete)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'white'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const ManageProjects = () => {

    const data = useAuthStore(state => state.user)

    const success = useLocation()?.state || {}
    const updateData = useLocation()?.state || {};
    const { update, projectIdFromDash, projectTitleFromDash, projectDescriptionFromDash, projectTechFromDash, projectCurrentReqFromDash, isPrivateFromDash, budgetFromDash } = updateData;

    const navigate = useNavigate()

    const [displayMessage, setDisplayMessage] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setDisplayMessage(false)
        }, 5000);
    }, [])

    const [newProjectForm, setNewProjectForm] = useState({
        projectId: projectIdFromDash || '',
        projectTechValue: '',
        projectHost: data.user._id,
        projectCoHost: '',
        isPrivate: isPrivateFromDash || false,
        projectTitle: projectTitleFromDash || '',
        projectDescription: projectDescriptionFromDash || '',
        budgetAmount: budgetFromDash?.budgetAmount || '',
        currency: budgetFromDash?.currency || '',
        isFlexible: budgetFromDash?.isFlexible || false,
        budgetDetails: budgetFromDash?.budgetDetails || ""
    })

    const [refresh, setRefresh] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(update || false)

    const isMdScreen = useMediaQuery('(min-width: 786px)');
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        getProject()
    }, [refresh])

    const [values, setValues] = useState({
        error: false,
        errorMessage: ''
    })

    const [addedProjects, setAddedProjects] = useState([])

    const [displayProjectTech, setDisplayProjectTech] = useState(update || false)
    const [displayform2, setDisplayForm2] = useState(false)
    const [displayProject, setDisplayProject] = useState(!update)

    const [projectTech, setProjectTech] = useState(projectTechFromDash || [])

    const [projectCurrentReq, setProjectCurrentReq] = useState(projectCurrentReqFromDash || [])

    const [projectReqData, setProjectReqData] = useState({
        title: '',
        description: ''
    })

    const { error, errorMessage } = values
    const { projectId, projectHost, projectTechValue, projectTitle, isPrivate, projectDescription, budgetAmount, currency, isFlexible, budgetDetails } = newProjectForm
    const { title, description } = projectReqData

    const handleChange = (name) => (event) => {
        if (name === 'projectTechValue') {
            setDisplayProjectTech(true);
        } else {
            setDisplayProjectTech(false);
        }

        let value = event.target.value;
        if (name === 'isPrivate' || name === 'isFlexible') {
            value = value === 'true' || value === true;
        } else {
            value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        }

        setNewProjectForm({ ...newProjectForm, [name]: value });
        setValues({ ...values, error: false, errorMessage: '' });
        setDisplayProject(false);
    };

    const handleQuillChange = (value) => {
        setNewProjectForm({ ...newProjectForm, projectDescription: value });
    };

    const handleDeleteTech = (ele) => {
        setProjectTech(projectTech.filter(e => e !== ele))
    }

    const handleProjectRequirement = name => event => {
        setProjectReqData({ ...projectReqData, [name]: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1) })
    }

    const handleQuillReqChange = value => {
        setProjectReqData({ ...projectReqData, description: value });
    };

    const updateProjectTech = (data) => {
        setProjectTech(projectTech => [...projectTech, data]);
        setNewProjectForm({ ...newProjectForm, projectTechValue: '' })
    }

    const appendRequirements = (data) => {
        setProjectCurrentReq(projectCurrentReq => [...projectCurrentReq, data])
        setProjectReqData({ title: '', description: '' })
    }

    const handleNextClick = () => {
        if (projectTitle === '') {
            return setValues({ ...values, error: true, errorMessage: 'Required' })
        }
        if (projectDescription === '') {
            return setValues({ ...values, error: true, errorMessage: 'Required' })
        }
        if (projectTech.length === 0) {
            return setValues({ ...values, error: true, errorMessage: 'required' })
        }
        setDisplayForm2(true)
    }

    const deleteCurrentReq = (p) => {
        setProjectCurrentReq(projectCurrentReq.filter(e => JSON.stringify(e) !== JSON.stringify(p)))
    }

    const handleSubmitProject = (data) => {
        createProject(data).then(res => {
            if (res) {
                setDisplayProject(true)
                setDisplayForm2(false)
                setRefresh(true)
                navigate('/advanced-dashboard')
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const getProject = () => {
        getUserProject(projectHost).then(res => {
            setAddedProjects(res.reverse())
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const prepareUpdate = ({ projectId, projectTitle, projectDescription, projectTech, projectCurrentReq, currency, budgetAmount, budgetDetails, isFlexible }) => {
        setNewProjectForm({
            ...newProjectForm, projectId: projectId, projectTitle: projectTitle, projectDescription: projectDescription,
            budgetAmount: budgetAmount,
            currency: currency,
            isFlexible: isFlexible,
            budgetDetails: budgetDetails
        })
        setProjectTech(projectTech)
        setProjectCurrentReq(projectCurrentReq)
        setDisplayForm2(false)
        setDisplayProjectTech(true)
        setDisplayProject(false)
        setUpdateFlag(true)
    }

    const handelDeleteProject = (projectId) => {
        deleteProject(projectId).then(res => {
            console.log(res)
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleUpdateProject = (projectId, updatedData) => {
        updateProject(projectId, updatedData).then(res => {
            setRefresh(true)
            navigate('/advanced-dashboard')

        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
        setDisplayProject(true)
        setDisplayForm2(false)
        setUpdateFlag(false)
        setNewProjectForm({
            ...newProjectForm,
            projectId: '',
            projectTitle: '',
            projectDescription: '',
            budgetAmount: '',
            currency: '',
            budgetDetails: '',
        })
        setProjectTech([])
    }

    useEffect(() => {

        const userLanguage = navigator.language || navigator.userLanguage;

        if (userLanguage.includes('en-IN')) {
            setNewProjectForm({ ...newProjectForm, currency: 'INR' });
        } else if (userLanguage.includes('en-US')) {
            setNewProjectForm({ ...newProjectForm, currency: 'USD' });
        } else if (userLanguage.includes('en-GB')) {
            setNewProjectForm({ ...newProjectForm, currency: 'GBP' });
        } else if (userLanguage.includes('en-CA')) {
            setNewProjectForm({ ...newProjectForm, currency: 'CAD' });
        } else if (userLanguage.includes('en-AU')) {
            setNewProjectForm({ ...newProjectForm, currency: 'AUD' });
        } else {
            setNewProjectForm({ ...newProjectForm, currency: 'INR' });
        }

        if (updateFlag) {
            setNewProjectForm({ ...newProjectForm, currency: budgetFromDash.currency })
        }
    }, []);

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className="container-fluid d-flex justify-content-center m-auto py-lg-5 py-3">
                    <div className="col-lg-6 col-12 p-lg-3 p-1 py-lg-5 py-3 d-flex justify-content-center rounded-3" style={{ minHeight: '70vh', overflowY: 'scroll', backgroundColor: '#daebf9' }}>
                        <div className="col-lg-8 col-12 bg-light rounded-3 shadow p-lg-3 p-2">
                            <div className="">
                                <Collapse in={!displayform2}>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            {updateFlag ? <h2>Updating Project</h2> : <h2>Project Form</h2>}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <CssTextField
                                                className='my-2 col-12 bg-white'
                                                error={errorMessage === "" ? false : true}
                                                id="custom-css-outlined-input"
                                                autoComplete="off"
                                                color={error ? "error" : ""}
                                                value={projectTitle}
                                                label={errorMessage === '' ? "Project Title" : errorMessage}
                                                onChange={handleChange('projectTitle')}
                                                variant="outlined" />
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="col-12 my-2">
                                                <span>Project Description</span>
                                                <ReactQuill
                                                    className="col-12"
                                                    id="taskDescription"
                                                    theme="snow"
                                                    value={projectDescription}
                                                    onChange={handleQuillChange}
                                                    placeholder="Write your project description here..."
                                                    modules={{
                                                        toolbar: [
                                                            ['bold', 'italic', 'underline'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            ['link'],
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center my-2">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Project Visibility</InputLabel>
                                                <Select
                                                    className="bg-light"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={isPrivate}
                                                    label="Select Visibility"
                                                    onChange={handleChange('isPrivate')}
                                                >
                                                    <MenuItem value={false}>Public</MenuItem>
                                                    <MenuItem value={true}>Private</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="d-flex justify-content-center align-item-center">
                                            <div className="d-flex justify-content-between align-items-center col-12 px-0" style={{ gap: "10px" }}>
                                                <div style={{ flex: 1 }}>
                                                    <CssTextField
                                                        className='mt-2 mb-1 col-12 bg-white'
                                                        error={errorMessage === "" ? false : true}
                                                        id="custom-css-outlined-input"
                                                        color={error ? "error" : ""}
                                                        value={projectTechValue}
                                                        label={errorMessage === '' ? "Tech Used for project" : errorMessage}
                                                        onChange={handleChange('projectTechValue')}
                                                        variant="outlined" />
                                                </div>
                                                <button
                                                    onClick={() => updateProjectTech(projectTechValue)}
                                                    className="btn fw-bold btn-primary rounded-pill"
                                                >
                                                    {isMdScreen ? "Add tech" : <FontAwesomeIcon size="1x" icon={faPlus} />}
                                                </button>
                                            </div>
                                        </div>
                                        <Collapse in={displayProjectTech}>
                                            <div className="d-flex justify-content-around">
                                                <div className="col-12 card bg-white border-primary">
                                                    <div className="col-12 d-flex flex-wrap ">
                                                        {projectTech.map((p, i) => (
                                                            <div key={i} className="bg-primary d-flex flex-wrap align-items-center rounded-pill p-1 px-2 m-2">
                                                                <span className="px-2 text-sm text-white m-0">{p}</span>
                                                                <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                                    <FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                        <div className="d-flex justify-content-center my-2">
                                            <CssTextField
                                                className='my-2 col-12 bg-white'
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                error={errorMessage === "" ? false : true}
                                                id="budget-amount-input"
                                                color={error ? "error" : ""}
                                                value={budgetAmount}
                                                label={errorMessage === '' ? "Project Budget Amount" : errorMessage}
                                                onChange={handleChange('budgetAmount')}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center my-2">
                                            <FormControl fullWidth>
                                                <InputLabel id="currency-select-label">Currency</InputLabel>
                                                <Select
                                                    className="bg-white"
                                                    labelId="currency-select-label"
                                                    id="currency-select"
                                                    value={currency}
                                                    label="Currency"
                                                    onChange={handleChange('currency')}
                                                >
                                                    <MenuItem value="INR">INR (₹)</MenuItem>
                                                    <MenuItem value="USD">USD ($)</MenuItem>
                                                    <MenuItem value="EUR">EUR (€)</MenuItem>
                                                    <MenuItem value="GBP">GBP (£)</MenuItem>
                                                    <MenuItem value="CAD">CAD (C$)</MenuItem>
                                                    <MenuItem value="AUD">AUD (A$)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="d-flex justify-content-center my-2">
                                            <FormControl fullWidth>
                                                <InputLabel id="is-flexible-label">Is the Budget Flexible?</InputLabel>
                                                <Select
                                                    className="bg-light"
                                                    labelId="is-flexible-label"
                                                    id="is-flexible"
                                                    value={isFlexible}
                                                    label="Is the Budget Flexible?"
                                                    onChange={handleChange('isFlexible')}
                                                >
                                                    <MenuItem value={true}>Yes</MenuItem>
                                                    <MenuItem value={false}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <CssTextField
                                                className='my-2 col-12 bg-white'
                                                error={errorMessage === "" ? false : true}
                                                id="budget-details-input"
                                                color={error ? "error" : ""}
                                                multiline
                                                // rows={4}
                                                value={budgetDetails}
                                                label={errorMessage === '' ? "Additional Budget Details" : errorMessage}
                                                onChange={handleChange('budgetDetails')}
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className="d-flex justify-content-center align-item-center py-3">
                                            <button onClick={() => handleNextClick()} className="btn btn-success rounded-pill col-12">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </Collapse>
                                <Collapse in={displayform2}>
                                    <div className="">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="m-0" style={{ flex: 1 }}>Current Requirement</h2>
                                            <button onClick={() => setDisplayForm2(false)} className="btn btn-warning rounded-pill">
                                                <FontAwesomeIcon size="1x" icon={faArrowLeft} /> back
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <CssTextField
                                                className='my-2 col-12 bg-white'
                                                error={errorMessage === "" ? false : true}
                                                id="custom-css-outlined-input"
                                                color={error ? "error" : ""}
                                                value={title}
                                                label={errorMessage === '' ? "Current requirement" : errorMessage}
                                                onChange={handleProjectRequirement('title')}
                                                variant="outlined" />
                                        </div>
                                        <div className="d-flex justify-content-center">

                                            <div className="col-12 my-2">
                                                <span>Description</span>
                                                <ReactQuill
                                                    className="col-12"
                                                    id="taskDescription"
                                                    theme="snow"
                                                    value={description}
                                                    onChange={handleQuillReqChange}
                                                    placeholder="Write your Requirement description here..."
                                                    modules={{
                                                        toolbar: [
                                                            ['bold', 'italic', 'underline'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            ['link'],
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-item-center pt-3">
                                            <div className="d-flex justify-content-center col-12">
                                                <div className="col-12">
                                                    <div className="col-12 d-flex justify-content-between pt-3">
                                                        <button onClick={() => appendRequirements({ title, description })} className="btn btn-success   rounded-pill col-12">
                                                            Add Requirements
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {projectCurrentReq?.length === 0 ? null : <div className="pt-3">
                                        <h4 className=" ">Project Current Requirements</h4>
                                        {projectCurrentReq.map((p, i) => (
                                            <div key={i}>
                                                <div className="d-flex align-items-start justify-content-between">
                                                    <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                    <div className="col-9">
                                                        <p className="m-0 fs-5  ">{p?.title}</p>
                                                        <p className="m-0 fs-5"  dangerouslySetInnerHTML={{ __html: p.description }}></p>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button onClick={() => deleteCurrentReq(p)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        ))}
                                    </div>
                                    }
                                    <div className="py-3">
                                        {updateFlag ?
                                            <button onClick={() => handleUpdateProject(projectId, { projectHost, projectTitle, projectDescription, projectTech, isPrivate, projectCurrentReq, budget: { budgetAmount, isFlexible, budgetDetails, currency } })} className="btn btn-info   rounded-pill col-12">
                                                Update
                                            </button> :
                                            <button onClick={() => handleSubmitProject({ projectHost, projectTitle, projectDescription, projectTech, isPrivate, projectCurrentReq, budget: { budgetAmount, isFlexible, budgetDetails, currency } })} className="btn btn-primary   rounded-pill col-12">
                                                Submit
                                            </button>
                                        }
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageProjects