import Navbar from "./Navbar";
import cli_1 from '../authFlow/assets/cli_1.png'
import devS_installation from '../authFlow/assets/devS_installation.png'
import { useEffect, useState } from "react";
import Footer from "./Footer";

const CLIPage = () => {

    const [platform, setPlatform] = useState('');
    const [downloadLink, setDownloadLink] = useState('');

    useEffect(() => {
        // Detect platform based on userAgent
        const userAgent = window.navigator.userAgent.toLowerCase();
        let detectedPlatform = '';
        let link = '';

        if (userAgent.includes('win')) {
            detectedPlatform = 'Windows';
            link = 'https://devsemble.com/files/setup-win.exe';
        } else if (userAgent.includes('mac')) {
            detectedPlatform = 'macOS';
            link = 'https://devsemble.com/files/setup-macos';
        } else if (userAgent.includes('linux')) {
            detectedPlatform = 'Linux';
            link = 'https://devsemble.com/files/setup-linux';
        } else {
            detectedPlatform = 'Unsupported';
        }

        setPlatform(detectedPlatform);
        setDownloadLink(link);
    }, []);

    const handleDownload = async () => {
        if (!downloadLink) return;

        try {
            const response = await fetch(downloadLink, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `setup-${platform.toLowerCase()}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    return (
        <>
            <Navbar />
            <div className="container my-5">
                <header className="text-center mb-5">
                    <h1 className="display-5 fw-bold">devSemble CLI</h1>
                    <p className="fs-4 text-muted">Effortless Collaboration for Developers</p>
                </header>

                {/* Download Section */}
                <section className="row align-items-center mb-5">
                    <div className="col-md-6">
                        <h2 className="fw-bold">Download and Setup</h2>
                        <p className="fs-5">
                            Get started by downloading the setup file and running it as an administrator. This process will automatically install the latest version of the CLI and configure it for your system.
                        </p>
                        {platform === 'Unsupported' ? (
                            <p>Sorry, your platform is not supported for this download.</p>
                        ) : (
                            <>
                                <p className="">Your platform is detected as: <strong>{platform}</strong></p>
                                <button
                                    onClick={handleDownload}
                                    className="btn btn-primary"
                                >
                                    Download for {platform}
                                </button>
                            </>
                        )}
                    </div>
                    <div className="col-md-6 text-center">
                        <img
                            src={devS_installation}
                            alt="Download Illustration"
                            className="img-fluid rounded-3 shadow"
                        />
                    </div>
                </section>

                {/* Features Section */}
                <section className="mb-5">
                    <h2 className="fw-bold mb-4 text-center">Features</h2>
                    <div className="row text-center g-4">
                        <div className="col-md-4">
                            <div className="p-3 shadow-sm border rounded-3">
                                <h3 className="fw-bold">Task Integration</h3>
                                <p className="fs-5">Easily manage tasks directly from your terminal with intuitive commands.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-3 shadow-sm border rounded-3">
                                <h3 className="fw-bold">GitHub Sync</h3>
                                <p className="fs-5">Seamlessly sync with GitHub for commits and task tracking.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-3 shadow-sm border rounded-3">
                                <h3 className="fw-bold">Streamlined Workflow</h3>
                                <p className="fs-5">Save time with shortcuts and automated setup processes.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Usage Section */}
                <section className="mb-5">
                    <h2 className="fw-bold mb-4 text-center">Usage Examples</h2>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img
                                src="https://via.placeholder.com/500x300"
                                alt="CLI Screenshot"
                                className="img-fluid rounded-3 shadow"
                            />
                        </div>
                        <div className="col-md-6">
                            <ul className="list-unstyled fs-5">
                                <li>
                                    <strong>Login:</strong> <code>devS login</code>
                                </li>
                                <li>
                                    <strong>Show Tasks:</strong> <code>devS show pending-tasks</code>
                                </li>
                                <li>
                                    <strong>Commit:</strong> <code>devS commit -m "task: completed setup"</code>
                                </li>
                                <li>
                                    <strong>Push to GitHub:</strong> <code>devS push</code>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Beta Version Notice */}
                <section className="text-center py-4 bg-light rounded-3">
                    <p className="fs-5 text-danger fw-bold">
                        Note: This is the beta version of the devSemble CLI. Please report any issues to our support team.
                    </p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default CLIPage;
