import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { useAuthStore } from '../../store/store';
import TextField from '@mui/material/TextField';
import { Autocomplete, FormControl, IconButton, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { updateProfile } from '../../actions/userProfileActions';
import AuthNavbar from "../AuthNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { getRepos, postGitCreds } from "../../actions/gitOAuthActions";
import { gitUrl } from "../../constants";

const UpdateProfile = () => {

    const updateData = useLocation()?.state || {};

    // const {workExprience, educationExprience, otherLinks} = updateData

    const data = useAuthStore(state => state.user)
    // const updateLocalUser = useAuthStore(state => state.updateUserProfileStatus)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()
    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const [isUpdate, setIsUpdate] = useState(false)
    const [values, setValues] = useState({
        text: '',
        errorMessage: '',
        error: ''
    })

    // useEffect(() => {
    //     redirect()
    // }, [])

    const redirect = () => {
        if (data.user.isReviewer === true) {
            return navigate('/review-lists')
        }
        if (data.user.isAdvancedAccount === true) {
            return navigate('/advanced-dashboard')
        }
    }

    const [workDisplay, setWorkDisplay] = useState(true)
    const [workFormDisplay, setWorkFormDisplay] = useState(true)
    const [eduDisplay, setEduDisplay] = useState(false)
    const [eduFormDisplay, setEduFormDisplay] = useState(false)
    const [otherLinksForm, setOtherLinkForm] = useState(false)
    const [displayOtherLink, setDisplayOtherLink] = useState(false)
    const [displayProfile, setDisplayProfile] = useState(true)
    const [displayViewData, setDisplayViewData] = useState(false)
    // const [verifiedSuccessStack, setVerifiedSuccessStack] = useState(false)
    // const [verifiedSuccessGit, setVerifiedSuccessGit] = useState(false)
    // const [loadingStack, setLoadingStack] = useState(false)
    // const [loadingGit, setLoadingGit] = useState(false)

    const [wvd, setwvd] = useState(false)
    const [evd, setevd] = useState(false)
    const [lvd, setlvd] = useState(false)

    const [index, setIndex] = useState(null)

    // const [stackUserName, setStackUserName] = useState('16819973')
    // const [gitUserName, setGitUserName] = useState('mihir06121')

    const [work, setWork] = useState({
        title: 'project 1',
        description: 'description 1',
        startDate: '',
        endDate: '',
        workLink: '',
        repoName: '',
        repoLink: '',
        currentlyPursuing: '',
        techValue: '',
        errorMessageWork: '',
        errorWork: ''
    })
    const [techUsed, setTechUsed] = useState([])

    const [education, setEducation] = useState({
        courseName: 'education 1',
        institutionName: 'education 1',
        currentlyPursuingEdu: '',
        eduErrorMessageWork: '',
        eduErrorWork: ''
    })

    const [linkData, setLinkData] = useState({
        linkTitle: '',
        linkHref: '',
        linkError: '',
        linkErrorMessage: '',
    })

    const [workExprience, setWorkExprience] = useState(updateData.workExprience || [])
    const [eduExprience, setEduExprience] = useState(updateData.eduExprience || [])
    const [otherLinks, setOtherLink] = useState(updateData.otherLinks || [])

    const { title, description, currentlyPursuing, workLink, techValue } = work

    const { courseName, institutionName, currentlyPursuingEdu } = education

    const { githubProfile, stackOverFlowProfile, linkTitle, linkHref } = linkData

    const { error, errorMessage } = values

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));

    const [openModelGit, setOpenModelGit] = useState(false)

    const location = useLocation();

    const [loading, setLoading] = useState(true)

    const [gitTokenAvailable, setGitTokenAvailable] = useState(false)

    const [responseLoading, setResponseLoading] = useState(false)

    useEffect(() => {
        getRepos(data.user._id).then(response => {
            if (response.success) {
                setGitTokenAvailable(true)
                setGitRepos([...response.privateRepos, ...response.publicRepos, ...response.collaboratorRepos])
                setLoading(false)
            } else {
                setGitTokenAvailable(false)
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const [selectedRepository, setSelectedRepository] = useState(null);
    const [gitRepos, setGitRepos] = useState([])

    const handleChangeWork = name => event => {
        setWork({ ...work, errorWork: '', errorMessageWork: '', [name]: event.target.value });
    };

    const handleChangeEdu = name => event => {
        setEducation({ ...education, eduError: '', eduErrorMessage: '', [name]: event.target.value });
    };

    const handleChangeLink = name => event => {
        setLinkData({ ...linkData, linkError: '', linkErrorMessage: '', [name]: event.target.value })
    }

    const handleCurrentlyPursuingEdu = (event) => {
        setEducation({ ...education, currentlyPursuingEdu: event.target.value, endDate: "Currently Working" })
    }

    const onAppendEduArray = (newInput) => {
        setEduExprience(eduExprience => [...eduExprience, newInput])
        setEduDisplay(true)
        setEducation({
            courseName: 'test',
            institutionName: 'test',
            eduErrorMessageWork: '',
            eduErrorWork: '',
            currentlyPursuingEdu: 'false'
        })
    }

    const updateWorkTech = (data) => {
        setTechUsed(techUsed => [...techUsed, data])
        setWork({ ...work, errorWork: '', errorMessageWork: '', techValue: '' });

    }
    const handleDeleteTech = (ele) => {
        setTechUsed(techUsed.filter(e => e !== ele))
    }

    const onAppendWorkArray = (newInput) => {
        setWorkExprience(workExprience => [...workExprience, newInput])
        setWorkDisplay(true)
        setWork({
            ...work,
            title: '',
            description: '',
            workLink: '',
            repoName: '',
            repoLink: '',
            currentlyPursuing: 'false',
            techValue: '',
            errorMessageWork: '',
            errorWork: ''
        })
    }

    const onAppendLinkArray = (newInput) => {
        setOtherLink(otherLinks => [...otherLinks, newInput])
        setDisplayOtherLink(true)
    }

    const deleteWork = (i) => {
        setWorkExprience(workExprience => {
            return workExprience.filter((_, index) => index !== i)
        })
    }
    const deleteEdu = (i) => {
        setEduExprience(eduExprience => {
            return eduExprience.filter((_, index) => index !== i)
        })
    }
    const deleteLink = (i) => {
        setOtherLink(otherLinks => {
            return otherLinks.filter((_, index) => index !== i)
        })
    }
    const deleteTech = (i) => {
        setWork({ ...work, techUsed: techUsed.filter(e => e !== i) })
    }

    const updateWork = (w) => {
        setWork(w)
        setSelectedRepository({ name: w.repoName, link: w.repoLink })
        setTechUsed(w.techUsed)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateEdu = (w) => {
        setEducation(w)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateLink = (w) => {
        setLinkData(w)
    }

    const onUpdateWorkArray = (index, newInput) => {
        console.log(index, newInput)
        let dummyArray = [...workExprience]
        dummyArray[index] = newInput
        setWorkExprience(dummyArray)
        setTechUsed([])
        setIsUpdate(false)
    }

    const onUpdateEduArray = (index, newInput) => {
        let dummyArray = [...eduExprience]
        dummyArray[index] = newInput
        setEduExprience(dummyArray)
        setIsUpdate(false)
    }


    const onUpdateLinkArray = (index, newInput) => {
        console.log(index)
        let dummyArray = [...otherLinks]
        dummyArray[index] = newInput
        setOtherLink(dummyArray)
        setIsUpdate(false)
    }

    const conformationCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any work exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setEduDisplay(true)
                setWorkDisplay(false)
            } else {
                setWorkFormDisplay(true)
                setOtherLinkForm(false)
                setEduFormDisplay(false)
            }
        } else {
            setWorkFormDisplay(false)
            setEduFormDisplay(true)
            setEduDisplay(true)
            setWorkDisplay(false)
        }
    }
    const conformationEduCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any education exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(false)
                setOtherLinkForm(true)
                setDisplayOtherLink(true)
                setEduDisplay(false)
            } else {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setOtherLinkForm(false)
                setEduDisplay(false)
            }
        } else {
            setDisplayOtherLink(true)
            setWorkFormDisplay(false)
            setEduFormDisplay(false)
            setOtherLinkForm(true)
            setEduDisplay(false)
        }
    }
    const conformationLinkCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any other links`) === true) {
                setDisplayViewData(true)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setDisplayOtherLink(false)
                setOtherLinkForm(false)
            } else {
                setDisplayViewData(false)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setOtherLinkForm(true)
            }
        } else {
            setDisplayViewData(true)
            setEduFormDisplay(false)
            setDisplayOtherLink(false)
            setWorkFormDisplay(false)
            setOtherLinkForm(false)
        }
    }
    const handleUpdateProfile = (profileData) => {
        updateProfile(profileData).then(res => {
            if (res.success) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const profile = res.profile;
                const updatedUser = {
                    ...currentUser,
                    message: "Your Profile is ready",
                    profileStatus: true,
                    profile: profile
                };

                localStorage.setItem('user', JSON.stringify(updatedUser));
                setStateUser(updatedUser);
                navigate('/dashboard')
            } else {
                console.log(res)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleLogin = () => {
        window.location.href = gitUrl;
    };

    const handleLoginGit = () => {
        window.location.href = gitUrl;
    };

    return (
        <AuthNavbar>
            {responseLoading && (
                <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        zIndex: 1050, // Ensure it's above all other elements
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="text-center text-white">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Loading, please wait...</p>
                    </div>
                </div>
            )}
            <div className=''>
                <div style={{ backgroundColor: "wihtesmoke" }} className="card border-0 my-md-5 my-3 mx-md-5 mx-3">
                    {/* <h3 align="center" className='fw-bold'>Updating Your Profile</h3> */}
                    {displayViewData === false ? <div className='row'>
                        {workFormDisplay === true ?
                            <div className='col-11 col-md-4 p-2 mx-auto'>
                                <h3 className="text-dark">Work Exprience Form</h3>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={title}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeWork('title')}
                                        variant="outlined" />
                                </div>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={description}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeWork('description')}
                                        variant="outlined" />
                                </div>

                                {loading ? <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <div>
                                        <CircularProgress size={30} />
                                    </div>
                                    <div>
                                        <span className="px-2">Loading Git Tokens</span>
                                    </div>
                                </div> : <div>
                                    {!gitTokenAvailable ? <div align="center" className="pt-2">
                                        <button
                                            className="btn btn-danger btn-sm fw-bold"
                                            onClick={handleLogin}>Integrate Your Github
                                        </button>
                                        <button
                                            className="btn btn-sm btn-outline-primary fw-bold mx-2"
                                            onClick={() => setOpenModelGit(true)}>
                                            ?
                                        </button>
                                        <Modal
                                            open={openModelGit}
                                            onClose={() => setOpenModelGit(false)}
                                        >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                <div className="p-md-3 p-2 card col-6">
                                                    <p>
                                                        Git Integration
                                                    </p>
                                                    <p>
                                                        include text model for github Integratation
                                                    </p>
                                                    <div align="center">
                                                        <button onClick={() => setOpenModelGit(false)} className="btn col-6 btn-primary">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div> : <div><div className="d-flex align-items-center">
                                        <div className="my-2 w-100">
                                            <FormControl fullWidth>
                                                <div className="">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={gitRepos.map(repo => ({ name: repo.name, link: repo.url }))}
                                                        getOptionLabel={(option) => option.name}
                                                        value={selectedRepository}
                                                        onChange={(event, newValue) => {
                                                            setSelectedRepository(newValue === '' ? null : newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Select Repository" />}
                                                    />
                                                    {/* Add error handling if needed */}
                                                </div>
                                            </FormControl>
                                        </div>
                                        <button
                                            className="btn btn-sm btn-danger fs-5 fw-bold m-2"
                                            onClick={() => setOpenModelGit(true)}>
                                            ?
                                        </button>
                                        <Modal
                                            open={openModelGit}
                                            onClose={() => setOpenModelGit(false)}
                                        >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                <div className="p-md-3 p-2 card col-6">
                                                    <p>
                                                        Git Integration
                                                    </p>
                                                    <p>
                                                        Include text model for github Integratation
                                                    </p>
                                                    <div align="center">
                                                        <button onClick={() => setOpenModelGit(false)} className="btn btn-sm col-6 fw-bold btn-primary">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                        <small className="fw-bold text-danger">*You have successfully integrated GitHub</small>
                                    </div>}
                                </div>
                                }
                                <div className='py-2'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={workLink}
                                        label={errorMessage === '' ? "Mention other link available" : errorMessage}
                                        onChange={handleChangeWork('workLink')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <div className="d-flex justify-content-center align-item-center">
                                        <div className="d-flex justify-content-between align-items-center col-12 px-0" style={{ gap: "10px" }}>
                                            <div style={{ flex: 1 }}>
                                                <TextField
                                                    className="my-2 w-100"
                                                    error={errorMessage === "" ? false : true}
                                                    id="custom-css-outlined-input"
                                                    color={error ? "error" : ""}
                                                    value={techValue}
                                                    label={errorMessage === '' ? "Tech Used" : errorMessage}
                                                    onChange={handleChangeWork('techValue')}
                                                    variant="outlined"
                                                />
                                            </div>
                                            <button
                                                onClick={() => updateWorkTech(techValue)}
                                                className="btn fw-bold btn-primary rounded-pill"
                                            >
                                                {isMdScreen ? "Add tech" : <FontAwesomeIcon size="1x" icon={faPlus} />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <div className="col-12 card bg-transparent border-primary">
                                            <div className="col-12 d-flex flex-wrap ">
                                                {techUsed.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill p-1 px-2 m-2">
                                                        <span className="px-2 fw-bold text-white m-0">{p}</span>
                                                        <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                            <FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {isUpdate ?
                                        <button className='col-12 rounded-pill my-3 fw-bold btn btn-warning'
                                            onClick={() => onUpdateWorkArray(index, {
                                                title,
                                                description,
                                                workLink,
                                                repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuing,
                                                techUsed
                                            })}>
                                            Update
                                        </button> :
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <button style={{ flex: 2 }} className='m-1 fw-bold rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendWorkArray({
                                                    title,
                                                    description,
                                                    workLink,
                                                    repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                    repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                    startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuing,
                                                    techUsed
                                                })}>
                                                Add Work Exprience
                                            </button>
                                            <button style={{ flex: 1 }} className='m-1 fw-bold rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationCheck(workExprience)
                                                }}>{workExprience.length === 0 ? <span className="fw-bold">{isMdScreen ? <>Skip <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}</span> : <span className="fw-bold">{isMdScreen ? <>NEXT <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}</span>}
                                            </button>
                                        </div>}
                                </div>
                            </div> : null}
                        {eduFormDisplay === true ?
                            <div className='col-11 col-lg-4 p-3 mx-auto'>
                                <h3 className="text-dark">Education Form</h3>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={courseName}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeEdu('courseName')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={institutionName}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeEdu('institutionName')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <RadioGroup
                                        value={currentlyPursuingEdu}
                                        onChange={handleCurrentlyPursuingEdu}
                                    >
                                        <div className={`d-flex flex-wrap justify-content-center ${isMdScreen ? "align-items-center" : "align-items-start"} flex-column flex-md-row`}>
                                            <div className="mb-2 mb-md-0" style={{ flex: 1 }}>
                                                <FormControlLabel
                                                    className="text-dark"
                                                    value={true}
                                                    control={<Radio />}
                                                    label="Currently Pursuing"
                                                />
                                            </div>
                                            <div className="" style={{ flex: 1 }}>
                                                <FormControlLabel
                                                    className="text-dark"
                                                    value={false}
                                                    control={<Radio />}
                                                    label="Currently Not Pursuing"
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div align="center" className='py-3'>
                                    {isUpdate ?
                                        <button className='col-12 rounded-pill fw-bold my-3 btn btn-warning'
                                            onClick={() => onUpdateEduArray(index, {
                                                courseName,
                                                institutionName,
                                                eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuingEdu
                                            })}>
                                            Update
                                        </button> :
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    setWorkFormDisplay(true)
                                                    setOtherLinkForm(false)
                                                    setWorkDisplay(true)
                                                    setEduDisplay(false)
                                                    setEduFormDisplay(false)
                                                }}>{isMdScreen ? <><FontAwesomeIcon size="1x" icon={faArrowLeft} /> BACK</> : <FontAwesomeIcon size="1x" icon={faArrowLeft} />}
                                            </button>
                                            <button style={{ flex: 3 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendEduArray({
                                                    courseName,
                                                    institutionName,
                                                    eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuingEdu
                                                })}>
                                                Add Education
                                            </button>
                                            <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationEduCheck(eduExprience)
                                                }}>{isMdScreen ? <>NEXT <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div> : null}
                        {otherLinksForm === true ?
                            <div className='col-11 col-lg-4 p-3 mx-auto'>
                                <h3 className="text-dark">Other Links</h3>
                                <Collapse in={true}>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%', textTransform: 'capitalize !important' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkTitle}
                                            label={errorMessage === '' ? "Title" : errorMessage}
                                            onChange={handleChangeLink('linkTitle')}
                                            variant="outlined" />
                                    </div>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkHref}
                                            label={errorMessage === '' ? "Link" : errorMessage}
                                            onChange={handleChangeLink('linkHref')}
                                            variant="outlined" />
                                    </div>
                                    <div align="center" className='py-3'>
                                        {isUpdate ?
                                            <button className='col-12 my-3 btn btn-warning'
                                                onClick={() => onUpdateLinkArray(index, {
                                                    linkTitle,
                                                    linkHref
                                                })}>
                                                Update
                                            </button> :
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setOtherLinkForm(false)
                                                        setEduFormDisplay(true)
                                                        setDisplayOtherLink(false)
                                                        setEduDisplay(true)
                                                    }}>{isMdScreen ? <><FontAwesomeIcon size="1x" icon={faArrowLeft} /> BACK</> : <FontAwesomeIcon size="1x" icon={faArrowLeft} />}
                                                </button>
                                                <button style={{ flex: 3 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                    onClick={() => onAppendLinkArray({
                                                        linkTitle,
                                                        linkHref
                                                    })}>
                                                    Add Link
                                                </button>
                                                <button
                                                    style={{ flex: 2 }}
                                                    className="fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm"
                                                    onClick={() => {
                                                        conformationLinkCheck(eduExprience);
                                                    }}
                                                >
                                                    {isMdScreen ? <>Profile <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <><FontAwesomeIcon size="1x" icon={faSave} /> <FontAwesomeIcon size="1x" icon={faArrowRight} /></>}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Collapse>
                            </div> : null}
                        <div className='text-dark col-11 col-lg-6 px-0 mx-auto'>
                            <Collapse className='' in={workDisplay}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Work exprience you added</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark rounded-pill'
                                            onClick={() => setWorkDisplay(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                        <div className='container-fluid my-0 p-2 my-md-3 shadow' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateWork(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-danger'
                                                        onClick={() => deleteWork(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div><b className="">Description:</b> {w.description}</div><br />
                                            <div>Repo: {w.repoName}</div>
                                            <div>Tech Used: {w.techUsed.map((t, i) => <small key={i} className='bg-primary text-white fw-bold rounded-pill mx-2 px-2 p-1'>{t}</small>)}</div>
                                        </div>)}</div> : null}
                                </div>
                            </Collapse>
                            <Collapse className='' in={eduDisplay}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Education you added</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark rounded-pill'
                                            onClick={() => setEduDisplay(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                        <div className='container-fluid shadow my-md-3 p-2' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>{w.courseName}</h4>
                                                    </span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateEdu(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteEdu(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.institutionName}</div><br />
                                        </div>)}</div> : null}
                                </div>
                            </Collapse>
                            <Collapse className='' in={displayOtherLink}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Other Links</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark rounded-pill'
                                            onClick={() => setDisplayOtherLink(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {otherLinks.length !== 0 ? <div className='py-0'>{otherLinks.map((w, i) =>
                                        <div className='container-fluid shadow my-md-3 p-2' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'>
                                                        <h4>{w.linkTitle}</h4>
                                                    </span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateLink(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteLink(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div><b className="">Link:</b> {w.linkHref}</div><br />
                                        </div>)}</div> : null}
                                </div>
                            </Collapse>
                            <Collapse className='d-lg-block d-none' in={!workDisplay && !eduDisplay && !displayOtherLink}>
                                <div align='center' className='py-2'>
                                    <h3>View Your</h3>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setWorkDisplay(true)
                                                setWorkFormDisplay(true)
                                                setOtherLinkForm(false)
                                                setEduFormDisplay(false)
                                            }}>
                                            {`Work - ${workExprience.length}`}
                                        </span>
                                    </div>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setEduDisplay(true)
                                                setWorkFormDisplay(false)
                                                setOtherLinkForm(false)
                                                setEduFormDisplay(true)
                                            }}>
                                            {`Education - ${eduExprience.length}`}
                                        </span>
                                    </div>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setDisplayOtherLink(true)
                                                setWorkFormDisplay(false)
                                                setOtherLinkForm(true)
                                                setEduFormDisplay(false)
                                            }}>
                                            {`Links - ${otherLinks.length}`}
                                        </span>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div> : <div className='text-dark'>
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div align="start" className='py-3'>
                                    <button className='btn btn-warning btn-sm rounded-pill'
                                        onClick={() => {
                                            setWorkFormDisplay(true)
                                            setWorkDisplay(true)
                                            setDisplayViewData(false)
                                        }}>
                                        <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                                        <span className="px-2 fw-bold">Back</span>
                                    </button>
                                </div>
                                <div className="py-3">
                                    <div className='shadow py-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                            <h3 className="fw-normal">Work exprience you added</h3>
                                        </div>
                                        <hr className="mx-3" />
                                        {workExprience?.length !== 0 ? <div className='py-2'>{workExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div style={{ flex: 2 }}>
                                                        <h4>{w.title}</h4>
                                                    </div>
                                                    <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>
                                                        <button className='btn btn-warning btn-sm rounded-pill'
                                                            onClick={() => {
                                                                setWorkFormDisplay(true)
                                                                setWorkDisplay(true)
                                                                setIsUpdate(true)
                                                                setIndex(i)
                                                                updateWork(w)
                                                                setDisplayViewData(false)
                                                            }}><span className="fw-bold pe-md-2">{isMdScreen ? ' Update' : ''}</span><FontAwesomeIcon size="1x" icon={faEdit} /></button>
                                                        <button className='btn rounded-pill btn-sm btn-danger'
                                                            onClick={() => deleteWork(i)}><span className="fw-bold pe-md-2">{isMdScreen ? ' Delete' : ''}</span><FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                </div>
                                                <div><b className=""></b>{w.description}</div><br />
                                                <div>REPO: {w.repoName} {w.repoLink}</div>
                                                <div className="fw-bold" style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <small key={i} className='bg-primary rounded-pill mx-2 px-2 p-1 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</small>)}</div>
                                                {workExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : null}
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className='shadow py-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                            <h3 className="fw-normal">Education exprience you added</h3>
                                        </div>
                                        <hr className="mx-3" />
                                        {eduExprience?.length !== 0 ? <div className='py-0'>{eduExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div style={{ flex: 2 }} className="">
                                                        <h4 className="mb-0">{w.courseName}</h4>
                                                        <small>({w.eduStartDate} - {w.eduEndDate})</small>
                                                    </div>
                                                    <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>
                                                        <button className='btn btn-warning btn-sm rounded-pill'
                                                            onClick={() => {
                                                                setEduFormDisplay(true)
                                                                setEduDisplay(true)
                                                                setIsUpdate(true)
                                                                setIndex(i)
                                                                updateEdu(w)
                                                                setDisplayViewData(false)
                                                            }}><span className="fw-bold pe-md-2">{isMdScreen ? ' Update' : ''}</span><FontAwesomeIcon size="1x" icon={faEdit} /></button>
                                                        <button className='btn rounded-pill btn-sm btn-danger'
                                                            onClick={() => deleteEdu(i)}><span className="fw-bold pe-md-2">{isMdScreen ? ' Delete' : ''}</span><FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                </div>
                                                <div><b className=""></b>{w.institutionName}</div><br />
                                                {eduExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : null}
                                    </div>
                                </div>
                                <div className="py-3">
                                    {otherLinks?.length !== 0 ?
                                        <div className='shadow py-3'>
                                            <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                                <h3 className="fw-normal">Other links you added</h3>
                                            </div>
                                            <hr className="mx-3" />
                                            {otherLinks?.length !== 0 ? <div className='py-0'>{otherLinks?.map((w, i) =>
                                                <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div style={{ flex: 2 }}>
                                                            <h4>{w.linkTitle}</h4>
                                                        </div>
                                                        <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>
                                                            <button className='btn btn-warning btn-sm rounded-pill'
                                                                onClick={() => {
                                                                    setOtherLinkForm(true)
                                                                    setDisplayOtherLink(true)
                                                                    setIsUpdate(true)
                                                                    setIndex(i)
                                                                    updateLink(w)
                                                                    setDisplayViewData(false)
                                                                }}><span className="fw-bold pe-md-2">{isMdScreen ? ' Update' : ''}</span><FontAwesomeIcon size="1x" icon={faEdit} /></button>
                                                            <button className='btn rounded-pill btn-sm btn-danger'
                                                                onClick={() => deleteLink(i)}><span className="fw-bold pe-md-2">{isMdScreen ? ' Delete' : ''}</span><FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                    <span className='d-flex align-items-center'></span>
                                                    <div><b className="">Link:</b> {w.linkHref}</div>
                                                    {otherLinks?.length - 1 === i ? null : <hr />}
                                                </div>)}</div> : <div className="py-3 text-center">
                                                <h4>No Links Added</h4>
                                            </div>}
                                        </div> : null
                                    }
                                </div>
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className='btn btn-primary btn-sm rounded-pill'
                                        onClick={() => {
                                            setResponseLoading(true)
                                            handleUpdateProfile({
                                                userId: data.user._id,
                                                workExprience: workExprience,
                                                educationExprience: eduExprience,
                                                otherLinks: otherLinks,
                                                githubProfile: githubProfile,
                                                stackOverFlowProfile: stackOverFlowProfile
                                            })
                                        }}>
                                        <FontAwesomeIcon size="1x" icon={faSave} /><span className="ps-2 fw-bold">Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}</div>
            </div>
        </AuthNavbar>
    )
}

export default UpdateProfile

