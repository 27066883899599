import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/store";
import { checkServer } from "../actions/authActions";

// Component to authorize users
export const AuthorizeUser = ({ children }) => {
    const userData = localStorage.getItem('user');
    console.log(userData)
    // If user data is not found in localStorage, redirect to home page
    if (!userData) {
        return <Navigate to={'/auth'} replace={true}></Navigate>;
    }

    // If user data exists, render children
    return children;
};

export const ProtectRoute = ({ children }) => {
    const [isServerUp, setIsServerUp] = useState(null); // null: loading, true: server up, false: server down
    const user = useAuthStore(state => state.user);
    const currentPath = window.location.pathname;

    useEffect(() => {
        const checkServerStatus = async () => {
            try {
                const res = await checkServer();
                setIsServerUp(res?.server_status || false);
            } catch (error) {
                console.error("Error checking server status:", error);
                setIsServerUp(false); // Handle as server down
            }
        };

        checkServerStatus();
    }, []);

    // Show a loading placeholder while server status is being checked
    if (isServerUp === null) {
        return <div
            className="position-fixed top-0 start-0 w-100 h-100"
            style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                zIndex: 1050, // Ensure it's above all other elements
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="text-center text-white">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Loading, please wait...</p>
            </div>
        </div>;
    }

    // If server is down, redirect to the maintenance page
    if (!isServerUp) {
        return <Navigate to={'/site-under-maintenance'} replace={true} />;
    }

    // If server is up, proceed with user-based route protection
    if (!user) {
        return <Navigate to={'/'} replace={true} />;
    }

    if (user.user.isAdvancedAccount &&
        (currentPath === "/dashboard" || currentPath === "/review-lists" || currentPath === "/reviewer-verification")) {
        return <Navigate to={'/advanced-dashboard'} replace={true} />;
    }

    if (!user.user.isAdvancedAccount && !user.user.isReviewer &&
        (currentPath === "/review-lists" || currentPath === "/reviewer-verification")) {
        return <Navigate to={'/dashboard'} replace={true} />;
    }

    // Render children if all conditions are met
    return children;
};


// Component to set user state from localStorage
export const SetStateProvider = ({ children }) => {
    const setLocalUser = useAuthStore(state => state.setUserFromLocalStorage)
    setLocalUser(JSON.parse(localStorage.getItem('user')))
    return children
}

export const PreventAuthFlow = ({ children }) => {
    const [isServerUp, setIsServerUp] = useState(null); // null: loading, true: server up, false: server down
    const user = useAuthStore(state => state.user);

    useEffect(() => {
        const checkServerStatus = async () => {
            try {
                const res = await checkServer();
                setIsServerUp(res?.server_status || false);
            } catch (error) {
                console.error("Error checking server status:", error);
                setIsServerUp(false); // Treat errors as server down
            }
        };

        checkServerStatus();
    }, []);

    // Show loading state while checking server status
    if (isServerUp === null) {
        return <div
            className="position-fixed top-0 start-0 w-100 h-100"
            style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                zIndex: 1050, // Ensure it's above all other elements
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="text-center text-white">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Loading, please wait...</p>
            </div>
        </div>;
    }

    // Redirect to maintenance page if server is down
    if (!isServerUp) {
        return <Navigate to={'/site-under-maintenance'} replace={true} />;
    }

    // Redirect logic based on user roles and verification status
    if (user) {
        if (user.user.isAdvancedAccount) {
            return <Navigate to={'/advanced-dashboard'} replace={true} />;
        } else {
            return <Navigate to={'/dashboard'} replace={true} />;
        }
    }

    // Render children if user is not logged in
    return children;
};

// Component to prevent non-verified reviewers from accessing certain routes
export const PreventNonVerifiedReviewers = ({ children }) => {
    const user = useAuthStore(state => state.user);

    // Redirect non-verified reviewers to reviewer verification page
    if (user && user.user.isReviewer && !user.user.isReviewerVerified) {
        return <Navigate to={'/reviewer-verification'} replace={true} />;
    } else {
        // Render children for other users or when user is not logged in
        return children;
    }
};

// Component to prevent users without advanced accounts from accessing certain routes
export const AllowAdvancedAccount = ({ children }) => {
    const user = useAuthStore(state => state.user);
    // console.log("h vbipo", user.user.isAdvancedAccount)

    // Redirect users without advanced accounts to dashboard
    if (user && !user.user.isAdvancedAccount) {
        return <Navigate to={"/dashboard"} replace={true} />;
    } else {
        // Render children for other users or when user is not logged in
        return children;
    }
};
