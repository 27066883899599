import React, { useState, useEffect } from 'react';
import { fetchNotesByProject, createNote, updateNote, deleteNote } from '../../actions/noteActions';
import { useParams } from 'react-router-dom';
import { TextField, List, Typography, Autocomplete, Collapse } from '@mui/material';
import { useAuthStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const Notes = ({ projectTasks = [], teamMembers = [] }) => {
    const { pId: projectId } = useParams();
    const [notes, setNotes] = useState([]);
    const [content, setContent] = useState('');
    const [referencedTasks, setReferencedTasks] = useState([]);
    const [taggedUsers, setTaggedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showNotesForm, setShowNotesForm] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null); // To track the note being edited

    const data = useAuthStore(state => state.user);

    const loadNotes = async () => {
        setLoading(true);
        setError(null);
        try {
            const fetchedNotes = await fetchNotesByProject(projectId);
            setNotes(Array.isArray(fetchedNotes.notes.reverse()) ? fetchedNotes.notes : []);
        } catch (error) {
            console.error("Failed to load notes", error);
            setError("Failed to load notes. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadNotes();
    }, [projectId]);

    const handleCreateOrUpdateNote = async () => {
        if (!content.trim()) return;

        const noteData = {
            content,
            referencedTasks,
            taggedUsers,
            projectId,
            createdBy: data.user._id,
        };

        try {
            if (editingNoteId) {
                // Update existing note
                await updateNote(editingNoteId, noteData);
                setEditingNoteId(null); // Exit edit mode
            } else {
                // Create a new note
                await createNote(noteData);
            }
            setContent('');
            setReferencedTasks([]);
            setTaggedUsers([]);
            setShowNotesForm(false); // Hide the form after submission
            await loadNotes();
        } catch (error) {
            console.error("Error creating/updating note:", error);
            setError("Failed to create/update note. Please try again.");
        }
    };

    const handleDeleteNote = async (noteId) => {
        try {
            await deleteNote(noteId);
            setNotes(notes.filter((note) => note._id !== noteId));
        } catch (error) {
            console.error("Error deleting note:", error);
            setError("Failed to delete note. Please try again.");
        }
    };

    const startEditingNote = (note) => {
        setEditingNoteId(note._id); // Set note to edit mode
        setContent(note.content); // Pre-fill form with note data
        setReferencedTasks(note.referencedTasks.map((task) => task._id)); // Set referenced tasks
        setTaggedUsers(note.taggedUsers.map((user) => user._id)); // Set tagged users
        setShowNotesForm(true); // Open the form for editing
    };

    const cancelEdit = () => {
        setEditingNoteId(null); // Exit edit mode
        setContent('');
        setReferencedTasks([]);
        setTaggedUsers([]);
        setShowNotesForm(false); // Close the form
    };

    return (
        <div className='py-1'>
            {/* Add Notes Section */}
            <div className='d-flex flex-wrap justify-content-between align-items-center bg-white shadow-sm p-1'>
                <h2>Project Notes</h2>
                <button onClick={() => {
                    setShowNotesForm(!showNotesForm)
                    setEditingNoteId(null);
                    setContent('');
                    setReferencedTasks([]);
                    setTaggedUsers([]);
                }}
                    className={`btn fw-bold rounded-pill ${showNotesForm ? 'btn-danger' : 'btn-primary'}`}>
                    {showNotesForm ? 'Close' : 'Add Notes'}
                </button>
            </div>

            <Collapse in={!showNotesForm}>
                <div className='py-3' style={{ overflow: 'hidden' }}>
                    {loading ? (
                        <Typography variant="body1">Loading notes...</Typography>
                    ) : error ? (
                        <Typography variant="body1" color="error">{error}</Typography>
                    ) : notes.length === 0 ? (
                        <Typography variant="body1">No notes available for this project.</Typography>
                    ) : (
                        <List>
                            {notes.map((note, i) => (
                                <div key={i}>
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="fs-4 fw-bold">{note.content}</span>
                                            <div>
                                                <button
                                                    className="btn btn-sm btn-warning fw-bold me-2"
                                                    onClick={() => startEditingNote(note)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger fw-bold"
                                                    onClick={() => handleDeleteNote(note._id)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                        {note.referencedTasks?.length > 0 && (
                                            <div className="mt-2 fs-6">
                                                <span className="fw-bold">Referenced Tasks: </span>
                                                {note.referencedTasks.map((task, index) => (
                                                    <span key={task._id}>
                                                        {task?.taskTitle || 'Untitled Task'}
                                                        {index < note.referencedTasks.length - 1 && ', '}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                        {note.taggedUsers?.length > 0 && (
                                            <div className="mt-2 fs-6">
                                                <span className="fw-bold">Tagged Users: </span>
                                                {note.taggedUsers.map((user, index) => (
                                                    <span key={user._id}>
                                                        {user?.firstName || 'Unnamed User'}
                                                        {index < note.taggedUsers.length - 1 && ', '}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                        <small>
                                            <div className="d-flex fw-bold justify-content-between text-muted mt-2">
                                                <div>Created by: {note?.createdBy?.firstName || 'Unknown'}</div>
                                                <div>Created at: {new Date(note.createdAt).toLocaleString()}</div>
                                            </div>
                                        </small>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </List>
                    )}
                </div>
            </Collapse>

            <Collapse in={showNotesForm}>
                {/* Create/Update Note Form */}
                <div className='p-3'>
                    <TextField
                        label="Note Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        margin="normal"
                        required
                    />
                    {/* Referenced Tasks */}
                    <Autocomplete
                        className='py-2'
                        multiple
                        options={projectTasks}
                        getOptionLabel={(task) => task?.taskTitle || ''}
                        value={projectTasks.filter((task) => referencedTasks.includes(task._id))}
                        onChange={(e, newValue) => {
                            setReferencedTasks(newValue.map((task) => task._id));
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Referenced Tasks" placeholder="Select tasks" />
                        )}
                    />
                    {/* Tagged Users */}
                    <Autocomplete
                        className='py-2'
                        multiple
                        options={teamMembers}
                        getOptionLabel={(user) => user?.firstName || ''}
                        value={teamMembers.filter((user) => taggedUsers.includes(user._id))}
                        onChange={(e, newValue) => {
                            setTaggedUsers(newValue.map((user) => user._id));
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Tag Users" placeholder="Select users" />
                        )}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <button
                            onClick={handleCreateOrUpdateNote}
                            className={`btn ${editingNoteId ? 'btn-warning' : 'btn-primary col-12'} fw-bold rounded-pill`}
                        >
                            {editingNoteId ? 'Update Note' : 'Add Note'}
                        </button>
                        {editingNoteId && (
                            <button
                                onClick={cancelEdit}
                                className="btn btn-danger fw-bold rounded-pill ms-2"
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default Notes;
