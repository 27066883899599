import { useEffect, useState } from "react"
// import { getScore } from "../actions/reviewActions"
import { fetchRankedUsers } from "../actions/projectActions"
import Navbar from "./Navbar"
import { Link } from "react-router-dom"

const PublicDisplayRankList = () => {

    const [list, setList] = useState([])

    useEffect(() => {
        fetchRankedUsers().then(res => {
            setList(res?.reverse())
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className="py-md-5 py-2">
                <div>
                    <div className="container-fluid d-flex flex-wrap justify-content-center col-md-6 col-11 p-md-4 p-2 align-items-center rounded-3" style={{
                        backgroundColor: '#daebf9'
                    }}>
                        <div className="card border-0 bg-transparent col-md-8 col-12">
                            {list.map((l, i) => (
                                <div key={i} style={{
                                    borderBottom: '0.15rem solid grey',
                                    borderWidth: 'thin',
                                    borderTopLeftRadius: '0.30rem',
                                    borderTopRightRadius: '0.30rem',
                                    backgroundColor: 'white'
                                }} className="p-2 my-1" >
                                    <Link to={`${l._id}`} className="text-decoration-none text-dark">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-6 fs-5   ">
                                                    <span className="text-decoration-none text-dark">{l?.userId?.firstName} {l?.userId?.lastName}</span>
                                                </div>
                                                <div className="col-6 text-end">
                                                    {l?.rankRange === null ? 'Not Ranked' : <>{l?.rankRange}</>}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicDisplayRankList