import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import './VoiceCall.css';

const VoiceCall = ({ projectId }) => {
    const [stream, setStream] = useState(null);
    const [remoteStreams, setRemoteStreams] = useState([]);
    const [isMuted, setIsMuted] = useState(false);
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [callStarted, setCallStarted] = useState(false);
    const socketRef = useRef();
    const peerConnections = useRef({});

    const getMediaPermissions = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            return mediaStream;
        } catch (error) {
            console.error("Error accessing media devices.", error);
            alert("Please allow access to the camera and microphone.");
        }
    };

    const startCall = async () => {
        const localStream = await getMediaPermissions();
        setStream(localStream);
        localStream.getTracks().forEach((track) => {
            track.enabled = true;
        });
        setCallStarted(true);
        socketRef.current.emit('joinRoom', { projectId });
    };

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_SOCKET);

        socketRef.current.on('participantJoined', handleNewParticipant);
        socketRef.current.on('offer', handleReceiveOffer);
        socketRef.current.on('answer', handleReceiveAnswer);
        socketRef.current.on('ice-candidate', handleNewICECandidate);
        socketRef.current.on('leaveRoom', handleLeaveRoom);

        return () => {
            leaveCall();
            socketRef.current.disconnect();
        };
    }, []);

    const handleNewParticipant = (participantId) => {
        console.log(`New participant joined: ${participantId}`);
        const peerConnection = createPeerConnection(participantId);

        // Log the current local stream status
        console.log('Current local stream:', stream);

        // Add local stream tracks if available
        if (stream) {
            stream.getTracks().forEach((track) => {
                console.log('Adding local track to peer connection:', track);
                peerConnection.addTrack(track, stream);
            });
        }
        createOfferAndSend(peerConnection, participantId);
    };


    const createOfferAndSend = async (peerConnection, participantId) => {
        try {
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            socketRef.current.emit('offer', { offer, to: participantId });
        } catch (error) {
            console.error("Error creating or sending offer:", error);
        }
    };

    const createPeerConnection = (participantId) => {
        const peerConnection = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
        });

        peerConnection.onicecandidate = (event) => {
            console.log('Sending ICE candidate:', event.candidate);
            if (event.candidate) {
                socketRef.current.emit('ice-candidate', { candidate: event.candidate, to: participantId });
            }
        };

        peerConnection.ontrack = (event) => {
            console.log(`Received remote stream from: ${participantId}`);
            setRemoteStreams((prevStreams) => {
                const existingStream = prevStreams.find(stream => stream.id === event.streams[0].id);
                if (!existingStream) {
                    console.log('Adding new remote stream:', event.streams[0]);
                    return [...prevStreams, event.streams[0]];
                }
                return prevStreams;
            });
        };

        return peerConnection;
    };

    const handleReceiveOffer = async (offer, from) => {
        const peerConnection = createPeerConnection(from);
        peerConnections.current[from] = peerConnection;

        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        if (stream) {
            stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        }

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        socketRef.current.emit('answer', { answer, to: from });
    };

    const handleReceiveAnswer = async (answer, from) => {
        await peerConnections.current[from].setRemoteDescription(new RTCSessionDescription(answer));
    };

    const handleNewICECandidate = async (candidate, from) => {
        const iceCandidate = new RTCIceCandidate(candidate);
        await peerConnections.current[from].addIceCandidate(iceCandidate);
    };

    const handleLeaveRoom = (participantId) => {
        console.log(`Participant left: ${participantId}`);
        setRemoteStreams((prevStreams) => prevStreams.filter(stream => stream.id !== participantId));
    };

    const toggleMute = () => {
        if (stream) {
            const audioTrack = stream.getAudioTracks()[0];
            audioTrack.enabled = !audioTrack.enabled;
            setIsMuted(!audioTrack.enabled);
        }
    };

    const toggleVideo = () => {
        if (stream) {
            const videoTrack = stream.getVideoTracks()[0];
            videoTrack.enabled = !videoTrack.enabled;
            setIsVideoEnabled(videoTrack.enabled);
        }
    };

    const leaveCall = () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
        socketRef.current.emit('leaveRoom', { projectId });
        setRemoteStreams([]);
        setCallStarted(false);
    };

    return (
        <div className="google-meet-clone">
            <div className="video-grid">
                {stream && (
                    <video
                        className="local-video"
                        ref={(video) => video && (video.srcObject = stream)}
                        autoPlay
                        muted
                    />
                )}
                {remoteStreams.map((remoteStream) => (
                    <video
                        key={remoteStream.id}
                        className="remote-video"
                        ref={(video) => video && (video.srcObject = remoteStream)}
                        autoPlay
                    />
                ))}
            </div>
            <div className="controls">
                {!callStarted ? (
                    <button onClick={startCall}>Start Call</button>
                ) : null}
                <button onClick={toggleMute}>
                    <FontAwesomeIcon icon={isMuted ? faMicrophoneSlash : faMicrophone} />
                </button>
                <button onClick={toggleVideo}>
                    <FontAwesomeIcon icon={isVideoEnabled ? faVideoSlash : faVideo} />
                </button>
                <button onClick={leaveCall}>
                    <FontAwesomeIcon icon={faPhoneSlash} />
                </button>
            </div>
        </div>
    );
};

export default VoiceCall;
