import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navbar from "./Navbar"
import { useEffect, useState } from "react";
import task_management from '../authFlow/assets/Frame_22.png'
import collaboration from '../authFlow/assets/Frame_26.png'
import code_editior from '../authFlow/assets/Frame_24.png'
const LanderPage = () => {

    const groups = [
        {
            title: "Collaboration Made Simple",
            paragraphs: [
                {
                    heading: "Comprehensive Task Management",
                    content: "Manage tasks, deadlines, and communication in one platform to keep your team focused and productive.",
                    hoverImage: task_management,
                },
                {
                    heading: "Seamless Collaboration",
                    content: "Collaborate in real-time with a powerful live code editor designed for instant feedback, teamwork, and productivity.",
                    hoverImage: code_editior,
                },
            ],
            defaultImage: collaboration,
        },
        {
            title: "Showcase and Grow Your Expertise",
            paragraphs: [
                {
                    heading: "Skill-Driven Opportunities",
                    content: "Be matched with projects that value your expertise, thanks to devSemble's transparent ranking system.",
                    hoverImage: "https://via.placeholder.com/600x400?text=Image5",
                },
                {
                    heading: "Real-World Portfolio Building",
                    content: "Work on live projects that enhance your profile and make you stand out in your field.",
                    hoverImage: "https://via.placeholder.com/600x400?text=Image6",
                },
            ],
            defaultImage: "https://via.placeholder.com/600x400?text=Image4",
        },
        {
            title: "Tools to Supercharge Development",
            paragraphs: [
                {
                    heading: "GitHub-Integrated Workflow",
                    content: "Seamlessly link your projects to GitHub for easier commits and task tracking.",
                    hoverImage: "https://via.placeholder.com/600x400?text=Image8",
                },
                {
                    heading: "Insights and Analytics",
                    content: "Access detailed insights to monitor performance and refine strategies.",
                    hoverImage: "https://via.placeholder.com/600x400?text=Image9",
                },
            ],
            defaultImage: "https://via.placeholder.com/600x400?text=Image7",
        },
    ];

    const [hoveredParagraphs, setHoveredParagraphs] = useState(Array(groups.length).fill(null));

    const getDisplayedImage = (groupIndex) => {
        const hoveredIndex = hoveredParagraphs[groupIndex];
        if (hoveredIndex !== null) {
            return groups[groupIndex].paragraphs[hoveredIndex].hoverImage;
        }
        return groups[groupIndex].defaultImage;
    };

    const handleMouseEnter = (groupIndex, paragraphIndex) => {
        const updatedState = [...hoveredParagraphs];
        updatedState[groupIndex] = paragraphIndex;
        setHoveredParagraphs(updatedState);
    };

    const handleMouseLeave = (groupIndex) => {
        const updatedState = [...hoveredParagraphs];
        updatedState[groupIndex] = null;
        setHoveredParagraphs(updatedState);
    };

    return (
        <>
            <div>
                <div>
                    <Navbar />
                </div>
                <div
                    className="hero-section d-flex align-items-center py-5"
                    style={{
                        background: "linear-gradient(to right, #0275d8, #daebf9)",
                        color: "#fff",
                        minHeight: "80vh",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 text-start text-lg-start" data-aos="fade-up">
                                <h1 className="display-4 fw-bold">
                                    A place where developers assemble.
                                </h1>
                                <p className="lead mt-3">
                                    Join a community where skills meet opportunities, innovation
                                    thrives, and success is a shared journey. From projects to
                                    portfolios, build your legacy with <span className="fw-bold">devSemble</span>.
                                </p>
                                <div className="mt-4">
                                    <Link to="/auth">
                                        <button
                                            className="btn btn-primary btn-lg me-3"
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#0275d8",
                                                border: "none",
                                                boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                                            }}
                                        >
                                            Get Started Now
                                        </button>
                                    </Link>
                                    <a
                                        href="#how-it-works"
                                        className="btn btn-outline-light btn-lg"
                                        style={{
                                            borderColor: "#fff",
                                            color: "#fff",
                                        }}
                                    >
                                        Learn More
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0 d-none d-lg-block" data-aos="fade-left">
                                <img
                                    src="https://via.placeholder.com/700x400"
                                    alt="Team Collaboration"
                                    className="img-fluid rounded shadow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="py-5 bg-light">
                    <div className="container">
                        <h2 className="text-center fw-bold mb-4" data-aos="zoom-in">Why Choose devSemble?</h2>
                        <div className="row">
                            <div className="col-md-4 text-center mb-4" data-aos="fade-up">
                                <i className="bi bi-people display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">Real-time Collaboration</h5>
                                <p>Work together effortlessly.</p>
                            </div>
                            <div className="col-md-4 text-center mb-4" data-aos="fade-up" data-aos-delay="100">
                                <i className="bi bi-star display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">Fair Ranking System</h5>
                                <p>Get recognized for your skills.</p>
                            </div>
                            <div className="col-md-4 text-center mb-4" data-aos="fade-up" data-aos-delay="200">
                                <i className="bi bi-github display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">GitHub Integration</h5>
                                <p>Sync projects with GitHub.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5 bg-light">
                    <div className="container" style={{overflowX: 'hidden'}}>
                        {groups.map((group, groupIndex) => (
                            <div key={groupIndex} className="row align-items-evenly mb-5 py-5">
                                <h2 className="d-md-none d-block fw-bold mb-4 display-6" data-aos="fade-up" data-aos-anchor-placement="center-bottom">{group.title}</h2>
                                <div className="col-md-6 d-flex order-1 order-md-2 pb-3"data-aos="fade-left" data-aos-anchor-placement="center-bottom">
                                    <img
                                        src={getDisplayedImage(groupIndex)}
                                        alt={group.title}
                                        className="img-fluid rounded-3 m-auto img-animate"
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                    <h2 className="d-md-block d-none fw-bold mb-4 display-6" data-aos="fade-up" data-aos-anchor-placement="center-bottom">{group.title}</h2>
                                    {group.paragraphs.map((paragraph, paragraphIndex) => (
                                        <p
                                            key={paragraphIndex}
                                            className="mb-3 fs-5"
                                            onMouseEnter={() => handleMouseEnter(groupIndex, paragraphIndex)}
                                            onMouseLeave={() => handleMouseLeave(groupIndex)}
                                            data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-delay="100"
                                        >
                                            <strong>{paragraph.heading}: </strong>
                                            {paragraph.content}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}

                        {/* <div className="row align-items-evenly mb-5 py-5">
                            <h2 className="d-md-none d-block fw-bold mb-4 display-6">Collaboration Made Simple</h2>
                            <div className="col-md-6 d-flex order-1 order-md-2 pb-3">
                                <img
                                    src={task_management}
                                    alt="Collaboration Made Simple"
                                    className="img-fluid rounded-3 m-auto"
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                <h2 className="d-md-block d-none fw-bold mb-4 display-6">Collaboration Made Simple</h2>
                                <p className="mb-3 fs-5">
                                    <strong>Seamless Collaboration: <i>"Live code editor"</i></strong><br /> Collaborate in real-time with a powerful live code editor designed for instant feedback, teamwork, and productivity.
                                </p>
                                <p className="mb-3 fs-5">
                                    <strong>Comprehensive Task Management: <i>"Stay on track"</i></strong><br /> Manage tasks, deadlines, and communication in one platform to keep your team focused and productive.
                                </p>
                            </div>
                        </div>

                        <div className="row align-items-evenly mb-5 py-5">
                            <h2 className="d-md-none d-block fw-bold mb-4 display-6">Showcase and Grow Your Expertise</h2>
                            <div className="col-md-6 d-flex order-1 order-md-2 pb-3">
                                <img
                                    src="https://via.placeholder.com/600x400"
                                    alt="Showcase and Grow Your Expertise"
                                    className="img-fluid rounded-3 shadow-sm m-auto"
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                <h2 className="d-md-block d-none fw-bold mb-4 display-6">Showcase and Grow Your Expertise</h2>
                                <p className="mb-3 fs-5">
                                    <strong>Skill-Driven Opportunities: <i>"Get your skills shine"</i></strong><br /> Be matched with projects that value your expertise, thanks to devSemble's transparent ranking system.
                                </p>
                                <p className="mb-3 fs-5">
                                    <strong>Real-World Portfolio Building: <i>"Craft a winning portfolio"</i></strong><br /> Work on live projects that enhance your profile and make you stand out in your field.
                                </p>
                            </div>
                        </div>

                        <div className="row align-items-evenly mb-5 py-5">
                            <h2 className="d-md-none d-block fw-bold mb-4 display-6">Tools to Supercharge Development</h2>
                            <div className="col-md-6 d-flex order-1 order-md-2 pb-3">
                                <img
                                    src="https://via.placeholder.com/600x400"
                                    alt="Tools to Supercharge Development"
                                    className="img-fluid rounded-3 shadow-sm m-auto"
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                <h2 className="d-md-block d-none fw-bold mb-4 display-6">Tools to Supercharge Development</h2>
                                <p className="mb-3 fs-5">
                                    <strong>GitHub-Integrated Workflow: <i>"Effortless integration"</i></strong><br /> Seamlessly link your projects to GitHub for easier commits and task tracking.
                                </p>
                                <p className="mb-3 fs-5">
                                    <strong>Insights and Analytics: <i>"Data at your finger tips"</i></strong><br /> Access detailed insights to monitor performance and refine strategies.
                                </p>
                            </div>
                        </div>

                        <div className="row align-items-evenly mb-5 py-5">
                            <div className="col-md-6 d-flex order-1 order-md-2 pb-3">
                                <img
                                    src="https://via.placeholder.com/600x400"
                                    alt="Security and Rewards Built In"
                                    className="img-fluid rounded-3 shadow-sm m-auto"
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                <h2 className="fw-bold mb-4 display-6">Security and Rewards Built In</h2>
                                <p className="mb-3 fs-5">
                                    <strong>Flexible Payment Structures: <i>"Live editing"</i></strong><br/> Earn staged payments as you contribute, ensuring fair rewards for milestones.
                                </p>
                                <p className="mb-3 fs-5">
                                    <strong>Secure and Scalable Infrastructure: <i>"Live editing"</i></strong><br/> A platform robust enough for startups and scalable for growing organizations.
                                </p>
                            </div>
                        </div>

                        <div className="row align-items-center py-5">
                            <div className="col-md-12">
                                <h2 className="fw-bold mb-4 display-6">A Community for Growth</h2>
                                <p className="mb-3 fs-5">
                                    <strong>Empowering Growth:</strong> Be part of a community that supports professional development and builds meaningful connections.
                                </p>
                                <button className="btn btn-primary btn-lg mt-3">Join Now</button>
                            </div>
                        </div> */}
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <h2 className="text-center fw-bold mb-4">How It Works</h2>
                        <div className="row text-center">
                            <div className="col-md-4 mb-4">
                                <i className="bi bi-person-plus display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">Sign Up</h5>
                                <p>Create your account and join the platform.</p>
                            </div>
                            <div className="col-md-4 mb-4">
                                <i className="bi bi-diagram-3 display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">Join/Host Projects</h5>
                                <p>Find or create projects that match your interests.</p>
                            </div>
                            <div className="col-md-4 mb-4">
                                <i className="bi bi-rocket display-4 text-primary mb-3"></i>
                                <h5 className="fw-bold">Collaborate & Grow</h5>
                                <p>Work with others and improve your skills.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Footer */}
                <div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default LanderPage