import { useEffect, useState } from 'react';
import Navbar from './Navbar'
import devSembleLogo from '../authFlow/assets/Frame_6devSemble_logo.png'
import Footer from './Footer';

const AboutUs = () => {

    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight / 2) {
                setShowNavbar(true);
            } else {
                setShowNavbar(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-lg-6 col-sm-8 col-11">
                    <div className="card bg-transparent border-0 py-md-5 py-3" >
                        <div className="row d-flex flex-wrap justify-content-between align-items-center">
                            <div className="col-md-4 col-4">
                                <img src={devSembleLogo} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            </div>
                            <div className="col-md-8 col-12 d-flex flex-wrap justify-content-between align-items-center">
                                <div className="card-body px-0">
                                    <h1 className="display-1 fw-bold" data-aos="fade-right" data-aos-delay="600">About Us</h1>
                                    <h3 className="" data-aos="fade-right" data-aos-delay="700">A place where developers assemble.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-justify">
                        <article className="text-justify fs-3 py-md-5 py-3">
                            <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                Welcome to <span className='text-primary fw-bolder'><b>devSemble</b></span>, your ultimate platform for collaboration and growth.
                                At <span className='text-primary fw-bolder'><b>devSemble</b></span>, we are committed to bridging the gap between learning and professional experience,
                                offering a dynamic environment where students, freelancers, and project listers can connect and thrive.
                            </div>
                            <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                Our mission is to foster a community that promotes practical learning,
                                portfolio building, and professional networking. By leveraging advanced algorithms
                                for profile review and ranking, we ensure fairness and efficiency in connecting the
                                best talent with exciting projects. Our platform supports real-time collaboration,
                                providing tools for seamless project management, communication, and resource sharing.
                            </div>
                            <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                At <span className='text-primary fw-bolder'><b>devSemble</b></span>, collaboration isn’t just about communication, it’s about creating together.
                                Our platform includes a collaborative code editor that supports popular programming languages,
                                allowing team members to work on code in real-time, no matter where they are. Paired with our task manager,
                                which integrates with Git repositories, team members can see live updates in individual tasks,
                                making it easier than ever to collaborate, track progress, and deliver results efficiently.
                                While we currently offer a robust editor and task management system, we're committed to future
                                enhancements like kanban boards and version control to take your collaboration to the next level.
                            </div>
                            <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                <span className='text-primary fw-bolder'><b>devSemble</b></span> is more than just a platform; it’s a vibrant ecosystem where talent meets opportunity.
                                We actively collaborate with educational institutions to offer students real-world project experience,
                                helping them to grow their skills and enhance their portfolios. For freelancers,
                                <span className='text-primary fw-bolder'><b> devSemble</b></span> is a gateway to diverse projects and career advancement.
                                For project listers, it’s a treasure trove of high-quality, relevant talent.
                            </div>
                            <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                Join us at <span className='text-primary fw-bolder'><b>devSemble</b></span> and be a part of a community where continuous learning, skill development,
                                and professional growth are at the forefront.
                                Together, we can build a future where everyone thrives.
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default AboutUs