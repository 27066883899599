import {apiUrl} from "../constants"

const user = JSON.parse(localStorage.getItem('user')); // Retrieve user from localStorage
const token = user?.token; // Extract the token

export const updateAdvAccount = (data) => {
    return fetch(`${apiUrl}/update-advanced-account/${data.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}